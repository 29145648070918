import React from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

import useTheme from '../../hooks/useTheme';
import createTheme from '../../theme';
import { SidebarItemsType } from '../../types/sidebar';
import SidebarNavList from './SidebarNavList';

const Title = styled(Typography)<
{ theme: any }
>`
  color: ${(props) => props.theme.sidebar.color};
  font-size: ${(props) => props.theme.typography.caption.fontSize};
  padding: ${(props) => props.theme.spacing(4)}
    ${(props) => props.theme.spacing(7)} ${(props) => props.theme.spacing(1)};
  opacity: 0.4;
  text-transform: uppercase;
  display: block;
`;

type SidebarNavSectionProps = {
  // className?: Element;
  component?: React.ElementType;
  pages: SidebarItemsType[];
  title?: string;
};

const SidebarNavSection: React.FC<SidebarNavSectionProps> = (props) => {
  const {
    title,
    pages,
    // className,
    component: Component = 'nav',
  } = props;

  const theme = createTheme(useTheme().theme);

  return (
    <Component>
      {title && <Title variant="subtitle2" theme={theme}>{title}</Title>}
      <SidebarNavList pages={pages} depth={0} />
    </Component>
  );
};
SidebarNavSection.defaultProps = {
  component: undefined,
  title: undefined,
};

export default SidebarNavSection;
