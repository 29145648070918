import React, { useEffect, useState } from 'react';
// import styled from 'styled-components/macro';
import { MoreVertical } from 'react-feather';

import {
  Card as MuiCard,
  CardHeader,
  IconButton,
  Paper as MuiPaper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled, spacing } from '@material-ui/system';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchWorkflows, selectWorkflows, WorkflowsStatuses } from '../../../redux/slices/workflows';
// import actions from '../../../redux/actions';
import useAppDispatch from '../../../hooks/useAppDispatch';
import useTheme from '../../../hooks/useTheme';
import createTheme from '../../../theme';
import { fetchEnvironments } from '../../../redux/slices/environments';
import { selectWorkspaces } from '../../../redux/slices/workspaces';
import { selectAuth } from '../../../redux/slices/auth';
import { IApplication } from '../../../definitions/application';

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled('div')<
{ theme: any }
>`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

interface TableProps {
  title: string;
  application?: IApplication;
}

const defaultProps = {
  application: undefined,
};

const Table: React.FC<TableProps> = (props): JSX.Element => {
  const { title, application } = props;
  const [hovered, setHovered] = useState<string | undefined>(undefined);
  const toggleHover = (hoveredRowId: string): void => {
    setHovered(hoveredRowId);
  };
  const workflows = useSelector(selectWorkflows);
  const workspaces = useSelector(selectWorkspaces);
  const currentWorkspace = workspaces?.currentWorkspace;
  const auth = useSelector(selectAuth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (auth.isAuthenticated && currentWorkspace) {
      // dispatch(fetchWorkflows(null));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWorkspace, auth.isAuthenticated]);
  const theme = createTheme(useTheme().theme);

  return (
    <Card mb={6}>
      <CardHeader
        action={(
          <IconButton aria-label="settings" size="large">
            <MoreVertical />
          </IconButton>
      )}
        title={title}
      />
      <Paper>
        <TableWrapper theme={theme}>
          <MuiTable>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                {/* <TableCell>Created</TableCell>
                <TableCell>Edited</TableCell> */}
                <TableCell>State</TableCell>
                <TableCell>Version</TableCell>
                {/* <TableCell>POC</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {workflows?.workflows && workflows?.workflows.map((row) => (
                <TableRow id={row.id} key={row.id} onMouseEnter={() => toggleHover(row.id)} onMouseLeave={() => toggleHover('')} onClick={() => navigate(`/workflows/${row.id}`)} style={row.id === hovered ? { background: theme.palette.action.hover, cursor: 'pointer' } : { cursor: 'pointer' }}>
                  <TableCell component="th" scope="row">
                    {row.meta?.name}
                  </TableCell>
                  {/* <TableCell>1 Oct 21</TableCell>
                  <TableCell>row.end</TableCell> */}
                  <TableCell>{row.versions ? 'Deployed' : 'Draft'}</TableCell>
                  <TableCell>
                    {row.latestVersion}
                    {' '}
                    {row.versions ? `(${row.versions[row.latestVersion].versionName})` : ''}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </MuiTable>
        </TableWrapper>
      </Paper>
    </Card>
  );
};

Table.defaultProps = defaultProps;

export default Table;
