import React, {
  useState, ChangeEvent, useEffect,
} from 'react';
import styled from 'styled-components/macro';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';
import {
  Button as MuiButton,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Typography,
  CardContent,
  TextField as MuiTextField,
  Card as MuiCard,
  FormControl as MuiFormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import {
  Add as AddIcon,
  DeleteOutline as DeleteOutlineIcon,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import { spacing, SpacingProps } from '@material-ui/system';
import { useDynamicList } from 'ahooks';
import { selectEnvironments } from '../../redux/slices/environments';
import useAppDispatch from '../../hooks/useAppDispatch';
import { addNewSecret, secretAdded, SecretCreateDTO } from '../../redux/slices/secrets';
import { selectWorkspaces } from '../../redux/slices/workspaces';

const ADD_NEW_SECRET_STRING = 'Add new secret';
const SECRET_INFO_STRING = 'Secret Info';

enum Labels {
  SecretName = 'Name',
  Environment = 'Environment',
  Value = 'Value',
}

const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)<{ m?: number }>`
  min-width: 148px;
`;

interface ButtonProps extends SpacingProps {
  component?: string;
}
const Button = styled(MuiButton)<ButtonProps>(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const Card = styled(MuiCard)(spacing);

const NewSecretForm = (): JSX.Element => {
  const { environments } = useSelector(selectEnvironments);
  const [secretName, setSecretName] = useState('');

  const workspaces = useSelector(selectWorkspaces);
  const {
    list, remove, getKey, insert, replace, resetList,
  } = useDynamicList([{ environmentId: '', value: '', showValue: false }]);

  const dispatch = useAppDispatch();

  const handleSecretNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSecretName(event.target.value);
  };

  const handleChange = (index: number, prop: string) => (event: any) => {
    const oldRow = list[index];
    const newRow = { ...oldRow, [prop]: event.target.value };
    replace(index, newRow);
  };

  const handleClickShowPassword = (index: number): void => {
    const oldRow = list[index];
    const newRow = { ...oldRow, showValue: !oldRow.showValue };
    replace(index, newRow);
  };

  const handleMouseDownPassword = (event: any): void => {
    event.preventDefault();
  };

  //   console.log('new form environments', environments);

  const handleRemove = (index: number) => (event: any) => remove(index);
  const handleAdd = (): void => {
    insert(list.length, { environmentId: '', value: '', showValue: false });
  };

  const handleSubmit = (): void => {
    if (!workspaces?.currentWorkspace?.id) throw new Error('undefined workspace');
    console.log('before transform', list);
    const newSecret: SecretCreateDTO = {
      name: secretName,
      id: uuid(),
      environments: list.map((x) => ({ environmentId: x.environmentId, secretValue: x.value })),
    };
    console.log('new Secet', newSecret);
    dispatch(secretAdded(newSecret));
    dispatch(addNewSecret(newSecret));
    setSecretName('');
    resetList([{ environmentId: '', value: '', showValue: false }]);
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {SECRET_INFO_STRING}
        </Typography>

        <Grid container spacing={4}>
          <Grid item md={4}>
            <TextField
              required
              id="service-name"
              label={Labels.SecretName}
              variant="outlined"
              value={secretName}
              onChange={handleSecretNameChange}
              fullWidth
              my={2}
            />
          </Grid>
          <Grid container item md={12}>
            {list.map(({ environmentId, value, showValue }, index) => (
              <Grid container item md={12} key={getKey(index)} spacing={2}>
                <Grid item md={2}>
                  <FormControl m={2}>
                    <InputLabel required id="interface-select-label">{Labels.Environment}</InputLabel>
                    <Select
                      labelId="service-environment-label"
                      id="service-environment"
                      value={environmentId}
                      label={Labels.Environment}
                      onChange={handleChange(index, 'environmentId')}
                    >
                      {Object.keys(environments)
                        .map((envId: string) => (
                          <MenuItem key={envId} value={envId}>
                            {environments[envId].name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={8}>
                  <TextField
                    id="environment-value"
                    label={Labels.Value}
                    type={showValue ? 'text' : 'password'}
                    variant="outlined"
                    value={value}
                    onChange={handleChange(index, 'value')}
                    fullWidth
                    my={2}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowPassword(index)}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showValue ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      ),
                    }}
                  />

                </Grid>
                {list.length !== 1 && (
                  <Grid container item xs="auto" alignItems="center">
                    <IconButton aria-label="remove value for environment" onClick={handleRemove(index)}>
                      <DeleteOutlineIcon />
                    </IconButton>
                  </Grid>
                )}
                {index === list.length - 1 &&
                  index < Object.keys(environments).length - 1 && (
                  <Grid container item xs="auto" alignItems="center">
                    <IconButton aria-label="add value for new environment" onClick={handleAdd}>
                      <AddIcon />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>
        <div>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            mt={4}
            onClick={handleSubmit}
          >
            <AddIcon />
            {ADD_NEW_SECRET_STRING}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default NewSecretForm;
