import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from '@mui/material';
import { spacing } from '@material-ui/system';
import { useSelector } from 'react-redux';
// import { green, red } from '@mui/material/colors';

import { selectAuth, selectUserData } from '../../../redux/slices/auth';
import Actions from './Actions';
import Stats from '../../../components/analytics/Stats';
import Table from './Table';
// import { selectWorkflows } from '../../../redux/slices/workflows';
import { selectWorkspaces } from '../../../redux/slices/workspaces';
import sorry from '../../../assets/sorry.png';
import useAppDispatch from '../../../hooks/useAppDispatch';
import { fetchWorkflows, selectWorkflows, WorkflowsStatuses } from '../../../redux/slices/workflows';

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

const getGreeting = (hour: number): string => {
  if (hour >= 2 && hour < 12) return 'Good morning';
  if (hour >= 12 && hour < 6) return 'Good afternoon';
  return 'Good evening';
};

const Default = (): JSX.Element => {
  const { t } = useTranslation();
  const hour = new Date(Date.now()).getHours();
  const userData = useSelector(selectUserData);
  const workspaces = useSelector(selectWorkspaces);
  const workflows = useSelector(selectWorkflows);
  const auth = useSelector(selectAuth);
  const currentWorkspace = workspaces?.currentWorkspace;
  const firstName = userData?.displayName?.split(' ')[0];
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (auth.isAuthenticated && currentWorkspace) {
      dispatch(fetchWorkflows(null));
    }
  }, [currentWorkspace, auth.isAuthenticated]);

  return (
    <>
      <Helmet title="Dashboard" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Dashboard
          </Typography>
          <Typography variant="subtitle1">
            {t(getGreeting(hour))}
            {` ${firstName}`}!
            {' '}
          </Typography>
        </Grid>
        <Grid item>
          <Actions />
        </Grid>
      </Grid>

      <Divider my={6} />

      { currentWorkspace && (
      <>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={6} lg={3} xl>
            <Stats
              title="WIPS"
              titleTooltip="Workflow Instances per Second"
              value="2.53"
              chip="Today"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl>
            <Stats
              title="Instances"
              titleTooltip="Workflow Instances"
              value="147,567"
              chip="Today"
            />
          </Grid>
          {/* <Grid item xs={12} sm={12} md={6} lg={3} xl>
            <Stats
              title="Errors"
              titleTooltip="Workflow Instance Errors"
              value="3"
              valueColor="#eb3458"
              chip="Today"
            />
          </Grid> */}
          {/* <Grid item xs={12} sm={12} md={6} lg={3} xl>
            <Stats
              title="Cost"
              titleTooltip="Total Costs"
              value="€ 1.54"
              chip="Today"
            />
          </Grid> */}
        </Grid>

        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={6} lg={3} xl>
            <Stats
              title="Active Applications"
              titleTooltip="Active Applications"
              value="4"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl>
            <Stats
              title="Active Workflows"
              titleTooltip="Active Workflows"
              value="20"
            />
          </Grid>
          {/* <Grid item xs={12} sm={12} md={6} lg={3} xl>
            <Stats
              title="System Status"
              titleTooltip="System Status"
              value="OK"
              valueColor="#66bb6a"
            />
          </Grid> */}
          <Grid item xs={12} sm={12} md={6} lg={3} xl />
        </Grid>

        <Grid container spacing={6}>
          {/* <Grid item xs={12} lg={8}>
          <LineChart />
        </Grid>
        <Grid item xs={12} lg={4}>
          <DoughnutChart />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={4}>
          <BarChart />
        </Grid> */}
          <Grid item xs={12} lg={6}>
            { currentWorkspace && <Table title="Latest Workflow Updates" /> }
            { !currentWorkspace && <div>Loading...</div> }
          </Grid>
        </Grid>
      </>
      )}
      {!currentWorkspace && (
        <>
          <div style={{
            textAlign: 'center',
            marginTop: '10%',
          }}
          >
            <div><img src={sorry} height="300px" alt="Sorry!" /></div>
            <div><h1>Sorry!</h1>Your account is not yet activated.</div>
          </div>
        </>
      )}
    </>
  );
};

export default Default;
