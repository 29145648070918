import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import actions from '../actions';
import { AsyncThunkConfig, RootState } from '../store';

// const secret1 = {
//     _id: 'secretId1',
//     workspaceId: '2370088f-5b6c-4ceb-a14e-742bb1b9df2a',
//     name: 'zendeskUser',
//     environments: {
//         'b807e0f2-b0cd-4906-b2b4-a4882a8cb642': {
//             secretValue: encryptedSecretsFreenow.zendeskUser,
//         },
//         '9b3d3350-de3f-4f90-99fc-5532e3725775': {
//             secretValue: encryptedSecretsFreenow.zendeskUser,
//         },
//     }
// };

type environmentId = string;

type Secret = {
    id: string,
    // workspaceId: string,
    name: string,
};

export type SecretFetchDTO = Secret & {
  environments:
  Record<environmentId, { secretValue: string}> // { id: string, secretValue: string }[]
};

export type SecretCreateDTO = Secret & {
  environments: {
     environmentId: string, secretValue: string}[]
};

/** Dont't save actual value? */
export type SecretWithoutValues = Secret & {
    environments: string[]
};

interface ISecretsState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    secrets: SecretWithoutValues[];
    error: string | null;
}

const initialState: ISecretsState = {
  status: 'idle',
  secrets: [],
  error: null,
};

export const fetchSecrets = createAsyncThunk<
SecretFetchDTO[], void, AsyncThunkConfig>(
  actions.secrets.LOAD,
  async (_, thunkApi) => {
    const { bitloops } = thunkApi.extra;
    const workspaceId = thunkApi.getState()?.workspaces?.currentWorkspace?.id;
    const response = await bitloops.request(
      'dae0d6cb-7568-4356-b7c9-6050ae31c5b7',
      '60cfb497-b97d-4fea-831e-87b660ae03e6',
      {
        workspaceId,
        workflowId: 'b626e48e-455e-43c5-8b60-6d5d9eaf6c59',
        nodeId: '03d55da6-eb61-433f-9a74-defd69e0ec7a',
        body: { workspaceId, command: 'all' },
      },
    );

    console.log('fetchSecrets', response.data);
    return response.data as SecretFetchDTO[];
  },
);

export const addNewSecret = createAsyncThunk<any, SecretCreateDTO, AsyncThunkConfig >(
  actions.secrets.CREATE,
  async (payload, thunkArg) => {
    // const detachedSecret = detachSecretValues(payload);
    const { name: secretName, environments } = payload;
    const bitloops = await thunkArg?.extra?.bitloops;
    const workspaceId = thunkArg.getState().workspaces?.currentWorkspace?.id;

    const response = await bitloops.request(
      'dae0d6cb-7568-4356-b7c9-6050ae31c5b7',
      '60cfb497-b97d-4fea-831e-87b660ae03e6',
      {
        workspaceId,
        workflowId: 'b626e48e-455e-43c5-8b60-6d5d9eaf6c59',
        nodeId: '03d55da6-eb61-433f-9a74-defd69e0ec7a',
        body: {
          workspaceId,
          command: 'createMany',
          secretName,
          environments,
        },
      },
    );
    console.log('response secrets', response);
    return response.response;
  },
);

export const removeSecret = createAsyncThunk<any, string, AsyncThunkConfig>(
  actions.secrets.REMOVE,
  async (payload, thunkApi) => {
    const secretName = payload;
    const bitloops = await thunkApi?.extra?.bitloops;
    const workspaceId = thunkApi.getState().workspaces?.currentWorkspace?.id;
    const response = await bitloops.request(
      'dae0d6cb-7568-4356-b7c9-6050ae31c5b7',
      '60cfb497-b97d-4fea-831e-87b660ae03e6',
      {
        workspaceId,
        workflowId: 'b626e48e-455e-43c5-8b60-6d5d9eaf6c59',
        nodeId: '03d55da6-eb61-433f-9a74-defd69e0ec7a',
        body: {
          workspaceId,
          command: 'delete',
          secretName,
        },
      },
    );
    return response;
  },
);

const secretsSlice = createSlice({
  name: 'secrets',
  initialState,
  reducers: {
    secretAdded(state, action: PayloadAction<SecretCreateDTO>) {
      const detachedSecret = {
        ...action.payload,
        environments: action.payload.environments.map((x) => x.environmentId),
      };
      state.secrets.push(detachedSecret);
    },
    secretRemoved(state, action: PayloadAction<string>) {
      console.log('Removed secret with id', action.payload);
      state.secrets = state.secrets.filter((secret) => secret.id !== action.payload);
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSecrets.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(fetchSecrets.fulfilled, (state, action) => {
      console.log('Fetch secrets fulffiled', action.payload.length);
      const detachedSecrets = action.payload.map(
        (secret) => ({ ...secret, environments: Object.keys(secret.environments) }),
      );
      // console.log('Fetch detachedSecrets', detachedSecrets);
      state.secrets = detachedSecrets;
      state.status = 'succeeded';
    });
    builder.addCase(fetchSecrets.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(addNewSecret.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(addNewSecret.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(addNewSecret.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(removeSecret.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(removeSecret.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(removeSecret.rejected, (state) => {
      state.status = 'failed';
    });
  },
});

export const { secretAdded, secretRemoved, reset: resetSecrets } = secretsSlice.actions;

export const selectSecrets = (state: RootState): ISecretsState => state.secrets;

export default secretsSlice.reducer;
