import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IBitloopsWorkflowDefinition, IBitloopsWorkflowDraftDefinition } from '../../definitions/workflow';
import actions from '../actions';
import { AppDispatch, RootState } from '../store';

export enum WorkflowsStatuses {
  PENDING = 'pending',
  OK = 'fulfilled',
  FAILED = 'rejected',
  LOGGED_OUT = 'logged out',
  IDLE = 'idle',
}

export interface IWorkflowsState {
  status: WorkflowsStatuses;
  workflows?: IBitloopsWorkflowDefinition[];
  workflowsById?: Map<string, IBitloopsWorkflowDefinition>;
}

const initialState = {
  status: WorkflowsStatuses.IDLE,
  workflows: [],
  workflowsById: undefined,
};

export const createWorkflow = createAsyncThunk<any, any, {
  dispatch: AppDispatch, state: RootState
}>(
  actions.workflows.CREATED_NEW,
  async (data: { workflowId: string, name: string, description: string }, thunkArg: any) => {
    const { workflowId, name, description } = data;
    const workspaceId = thunkArg.getState().workspaces.currentWorkspace.id;
    const bitloops = await thunkArg?.extra?.bitloops;
    const workflow: IBitloopsWorkflowDraftDefinition = {
      id: workflowId,
      workspaceId,
      meta: { name, description },
    };
    const response = await bitloops.request(
      'dae0d6cb-7568-4356-b7c9-6050ae31c5b7',
      '60cfb497-b97d-4fea-831e-87b660ae03e6',
      {
        workspaceId,
        workflowId: '817c0b6b-65a6-452c-88db-bf2b5a6d4030',
        nodeId: '6bc4ec4f-5a79-4515-963f-00e98c5f9bb8',
        body: { workspaceId, workflowId, workflow },
      },
    );
    return response;
  },
);

export const fetchWorkflows = createAsyncThunk<any, any, {
  dispatch: AppDispatch, state: RootState
}>(
  actions.workflows.FETCHED_ALL,
  async (applicationId, thunkArg: any) => {
    console.log('fetchWorkflows', applicationId);
    const bitloops = await thunkArg?.extra?.bitloops;
    const workspaceId = thunkArg.getState().workspaces.currentWorkspace.id;
    if (applicationId) {
      console.log('fetching workflows for application', applicationId);
      const response = await bitloops.request(
        'dae0d6cb-7568-4356-b7c9-6050ae31c5b7',
        '60cfb497-b97d-4fea-831e-87b660ae03e6',
        {
          workspaceId,
          workflowId: '5c2b251b-24a4-46e4-bed5-08c6616c5c42',
          nodeId: '116e0610-5319-4524-9d47-e7131f752497',
          body: { applicationId },
        },
      );
      return response;
    }
    const response = await bitloops.request(
      'dae0d6cb-7568-4356-b7c9-6050ae31c5b7',
      '60cfb497-b97d-4fea-831e-87b660ae03e6',
      {
        workspaceId,
        workflowId: '7dcd2115-4536-48a4-97c0-26cea7f21768',
        nodeId: 'c2115d23-f4da-46bb-aa86-0af8397cc4c7',
        body: { workspaceId },
      },
    );
    return response;
  },
);

// export const fetchWorkflow = createAsyncThunk<any, any, {
//   dispatch: AppDispatch, state: RootState
// }>(
//   actions.workflows.FETCHED_BY_ID,
//   async (workflowId, thunkArg: any) => {
//     // console.log('FETCHING WORKFLOWS!', thunkArg?.extra?.bitloops);
//     const bitloops = await thunkArg?.extra?.bitloops;
//     // console.log('Completed await for bitloops', thunkArg?.getState());
//     const workspaceId = thunkArg.getState().workspaces.currentWorkspace.id;
//     const response = await bitloops.request(
//       'dae0d6cb-7568-4356-b7c9-6050ae31c5b7',
//       '60cfb497-b97d-4fea-831e-87b660ae03e6',
//       {
//         workspaceId,
//         workflowId: '989dc5c2-ae96-489a-810d-5301ee71a283',
//         nodeId: 'c2115d23-f4da-46bb-aa86-0af8397cc4c7',
//         body: { workflowId, workspaceId },
//       },
//     );
//     // console.log('response workflows', response);
//     return response;
//   },
// );

export const updateWorkflowMeta = createAsyncThunk<any, any, {
  dispatch: AppDispatch, state: RootState
}>(
  actions.workflows.UPDATED_META,
  async (data, thunkArg: any) => {
    const { workflowId, updateData } = data;
    const bitloops = await thunkArg?.extra?.bitloops;
    console.log('sending update request for workflow meta', data);
    const response = await bitloops.request(
      'c9662a54-11c6-4243-a003-4541dea20b9f', '69048293-4200-4a8d-9c7d-b8d773cea76a', { entity: 'workflows', entityId: workflowId, updateData }, // 'workflows.updateEntityById'
    );
    console.log('response workflows', response);
    return response;
  },
);

const workflowsSlice = createSlice({
  name: 'workflows',
  initialState,
  reducers: {
    [actions.auth.SIGNED_OUT]: () => ({
      status: WorkflowsStatuses.LOGGED_OUT,
      workflows: undefined,
      workflowsById: undefined,
    }),
    variablesUpdated(state: IWorkflowsState, action) {
      const { workflowId, variables } = action.payload;
      if (state.workflows) {
        const existingWorkflow = state.workflows.find((workflow) => workflow.id === workflowId);
        if (existingWorkflow) {
          existingWorkflow.versions['1'].constants = variables;
        }
      }
    },
  },
  extraReducers: (builder) => {
    // fetchWorkflowById
    // builder.addCase(fetchWorkflow.fulfilled, (state, action) => {
    //   const status = WorkflowsStatuses.OK;
    //   const workflow: IBitloopsWorkflowDefinition = action.payload;
    //   const { workflowsById } = state;
    //   const newWorkflowById = JSON.parse(JSON.stringify(workflowsById));
    //   if (workflowsById) newWorkflowById[workflow.id] = workflow;
    //   return {
    //     ...state,
    //     status,
    //     workflowsById,
    //   };
    // });
    // fetchWorkflows
    builder.addCase(fetchWorkflows.pending, (state) => {
      const status = WorkflowsStatuses.PENDING;
      return { ...state, status };
    });
    builder.addCase(fetchWorkflows.rejected, (state) => {
      const status = WorkflowsStatuses.FAILED;
      return {
        ...state,
        status,
        workflow: undefined,
      };
    });
    builder.addCase(fetchWorkflows.fulfilled, (state, action) => {
      const status = WorkflowsStatuses.OK;
      let workflows = action.payload;
      const workflowsById: any = {};
      // TODO here when error type is object. Better solution would be
      // to have data, error in action.payload
      if (workflows && Array.isArray(workflows)) {
        workflows?.forEach((workflow: IBitloopsWorkflowDefinition) => {
          workflowsById[workflow.id] = workflow;
        });
      } else {
        workflows = [];
        console.error('Error: Workflows fetched are undefined:', action.payload);
      }
      console.log('workflowsById', workflowsById, workflows);
      return {
        ...state,
        status,
        workflows,
        workflowsById,
      };
    });
    // updateWorkflowMeta
    builder.addCase(updateWorkflowMeta.pending, (state) => {
      // const status = WorkflowsStatuses.LOADING;
      // return { ...state, status };
      console.log('pending meta update');
    });
    builder.addCase(updateWorkflowMeta.rejected, (state) => {
      console.error('Update workflowMeta rejected!');
      // const status = WorkflowsStatuses.FAILED;
      // return {
      //   ...state,
      //   status,
      //   workflow: undefined,
      // };
    });
    builder.addCase(updateWorkflowMeta.fulfilled, (state, action) => {
      console.log('SAVED!!!', action.payload);
      // const status = WorkflowsStatuses.OK;
      // const workflows = action.payload;
      // return {
      //   ...state,
      //   status,
      //   workflows,
      // };
    });
  },
});

export const selectWorkflows = (state: RootState): IWorkflowsState | undefined => {
  if (state.workflows) return state.workflows;
  return undefined;
};

export const selectWorkflowById =
(s: IWorkflowsState, id: string): IBitloopsWorkflowDefinition | undefined => {
  if (s.workflows) {
    return s.workflows.find(((workflow) => workflow.id === id));
  }
  return undefined;
};

// export const { clearWorkflows } = workflowsSlice.actions;

export const { variablesUpdated } = workflowsSlice.actions;

export default workflowsSlice.reducer;
