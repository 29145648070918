// import { nodeTypes } from '../components/editor/diagrams/Nodes';
import {
  ITypedVariable,
  IRequiredTypedVariable,
  IBitloopsInputConstant,
  ITypedStringVariable,
} from './workflow';

export enum NodeTypeName {
  Workflow = 'Workflow Settings', // used only for Workflow panel
  RequestStartNode = 'request-start-event',
  MessageStartNode = 'message-start-event',
  DirectStartNode = 'direct-start-event',
  MessageIntermediateNode = 'message-intermediate-event',
  EndNode = 'end-event',
  TaskNode = 'task-activity',
  SubProcessNode = 'sub-process-activity',
  TransactionNode = 'transaction-activity',
  CallNode = 'call-activity', // https://forum.camunda.org/t/difference-between-call-activity-and-subprocess/20153
  ExclusiveNode = 'exclusive-gateway',
  EventBasedNode = 'event-based-gateway',
  ParallelNode = 'parallel-gateway',
  InclusiveNode = 'inclusive-gateway',
  ExclusiveEventBasedNode = 'exclusive-event-based-gateway',
  ComplexNode = 'complex-gateway',
  ParallelEventBasedNode = 'parallel-event-based-gateway',
  TimerIntermediateNode = 'timer-intermediate-event',
}

export enum EdgeTypeName {
  TestEdge = 'test-edge'
}

export enum ServicesEnum {
  GRPC = 'gRPC',
  REST = 'REST',
  NATS = 'NATS',
  MESSAGE = 'MESSAGE',
  DYNAMIC_REST = 'DYNAMIC_REST',
}

export interface IVisual {
  x: number;
  y: number;
  io?: string;
}

export enum AuthenticationType {
  Anonymous = 'Anonymous',
  Token = 'Token',
  User = 'User',
  OAuth2 = 'OAuth2',
  Basic = 'Basic',
  XAPIKey = 'X_API_Key',
}

export type IBitloopsTriggerNodeType =
  IDirectStartNodeType | IMessageStartNodeType | IRequestStartNodeType;

export enum IntermediateMessageKeys {
  ADMIN = 'admin',
  EVENT = 'event',
  WORKFLOW_EVENT = 'workflow-event',
  PUBLISH_EVENT = 'publish-event',
}
export type AdminType = {
  evalTopic: string;
  command: string;
  payload: ITypedVariable[];
};

export type EventType = {
  messageId: string;
  payload: ITypedVariable[];
};

export enum RestMethodsEnum {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
  OPTIONS = 'OPTIONS'
}

export type PublishEventType = {
  type: string;
  topic: string;
  payload: ITypedVariable[];
};

export type NodeMessageType = {
  [IntermediateMessageKeys.ADMIN]?: AdminType[];
  [IntermediateMessageKeys.EVENT]?: EventType[];
  [IntermediateMessageKeys.PUBLISH_EVENT]?: PublishEventType[];
};

export interface INode {
  id: string;
  name: string;
  type: ITaskNodeType | ICallNodeType | IExclusiveNodeType | IEndNodeType |
    IRequestStartNodeType | IMessageStartNodeType | IDirectStartNodeType | IWorkflowNodeType |
    ITimerIntermediateNodeType;
  visual: IVisual;
  debugId?: string;
  messages?: NodeMessageType;
}

export interface IExclusiveNode extends INode {
  type: IExclusiveNodeType;
}
export interface IGrpcTaskNode extends INode {
  type: IGrpcTaskNodeType;
}
export interface IRestTaskNode extends INode {
  type: IRestTaskNodeType;
}
export interface IMessageTaskNode extends INode {
  type: IMessageTaskNodeType;
}

export interface ICallNode extends INode {
  type: ICallNodeType;
}

export interface ITaskNode extends INode {
  type: ITaskNodeType;
  executed: boolean;
}

export interface IEndNode extends INode {
  type: IEndNodeType;
}

export interface ITimerIntermediateNode extends INode {
  type: ITimerIntermediateNodeType;
}

export interface IBitloopsTriggerNode extends INode {
  type: IBitloopsTriggerNodeType;
}

export interface IRequestStartNode extends INode {
  type: IRequestStartNodeType;
}

export interface IDirectStartNode extends INode {
  type: IDirectStartNodeType;
}

export interface IMessageStartNode extends INode {
  type: IMessageStartNodeType;
}

export interface IWorkflowNode extends INode {
  type: IWorkflowNodeType;
  // name: NodeTypeName;
  constants?: Record<string, IBitloopsInputConstant[]>;
}

export type NodeHandlerParams = {
  nextNode: Node;
  variables: any;
  secrets: any;
  systemVariables: any;
};

export interface IAuthentication {
  type: AuthenticationType;
  user?: string;
  key?: string;
  Anonymous?: boolean;
  Token?: string;
  OAuth2?: string;
  Basic?: { username: string, password: string };
  'X_API_Key'?: string;
}

export interface ITimerIntermediateNodeParameters {
  timerDuration: number;
}

export interface ITimerIntermediateNodeType {
  name: NodeTypeName.TimerIntermediateNode;
  parameters: ITimerIntermediateNodeParameters;
}

export interface IDirectStartNodeType {
  name: NodeTypeName;
  workspaceId: string;
  workflowId: string;
  workflowVersion: string;
  input?: IRequiredTypedVariable[];
}

export interface IMessageStartNodeType {
  name: NodeTypeName;
  messageId: string;
  authentication: IAuthentication;
  input?: IRequiredTypedVariable[];
}

export interface IRequestStartNodeType {
  name: NodeTypeName;
  alias?: string;
  authentication: IAuthentication;
  input?: IRequiredTypedVariable[];
}

export interface ICallNodeType {
  name: NodeTypeName.CallNode;
  parameters: ICallNodeParameters;
  output?: ITypedVariable[];
}

export interface ICallNodeParameters {
  workflowId: string;
  nodeId: string;
  workflowVersion: number;
  input?: ITypedVariable[];
}

export interface ITaskNodeType {
  name: NodeTypeName.TaskNode;
  startedAt?: number;
  parameters: ITaskNodeParameters;
  output?: ITypedVariable[];
}

export interface ITaskNodeParameters {
  interface: IServiceInterfaceType;
  service: string;
  serviceVersion: string;
}

export interface IGrpcTaskInterface extends IServiceInterfaceType {
  input?: ITypedVariable[];
  grpcPackage?: string;
  grpcService: string;
  proto: string;
  rpc: string;
  ssl?: boolean;
  target: string;
}

export interface IRestTaskInterface extends IServiceInterfaceType {
  // input?: ITypedVariable[];
  method: RestMethodsEnum;
  uri: string; // base URL, obtained from service target
  urlPath: string; // URL path, appended to base URL
  headers?: ITypedStringVariable[] // Record<string, string>;
  body?: ITypedVariable[];
  query?: ITypedVariable[];
  params?: ITypedStringVariable[];
  swagger?: string;
}

export interface IMessageTaskInterface extends IServiceInterfaceType {
  topic: string;
  message: ITypedVariable[];
  proto?: string;
}

export interface IGrpcTaskParameters extends ITaskNodeParameters {
  interface: IGrpcTaskInterface;
}

export interface IRestTaskParameters extends ITaskNodeParameters {
  interface: IRestTaskInterface;
}

export interface IMessageTaskParameters extends ITaskNodeParameters {
  interface: IMessageTaskInterface;
}

export interface IGrpcTaskNodeType extends ITaskNodeType {
  parameters: IGrpcTaskParameters;
  // service: string; // TODO remove service and serviceVersion since they are inside parameters?
  // serviceVersion: string;
}

export interface IRestTaskNodeType extends ITaskNodeType {
  parameters: IRestTaskParameters;
  // service: string;
  // serviceVersion: string;
}

export interface IMessageTaskNodeType extends ITaskNodeType {
  parameters: IMessageTaskParameters;
}

export interface IWorkflowNodeType {
  name: NodeTypeName;
  constants?: Record<string, IBitloopsInputConstant[]>;
}

export interface IEndNodeType {
  name: NodeTypeName.EndNode;
  output: ITypedVariable[];
}

export interface IExclusiveNodeType {
  name: NodeTypeName.ExclusiveNode;
  parameters: IGatewayTypeParameters;
}

interface IGatewayDefaultCase {
  index?: number // if missing assume it's 0
  output?: ITypedVariable[];
}

export interface IGatewayCase extends IGatewayDefaultCase {
  evalValue: string;
  valueType: string;
  index: number;
}

export interface IGatewayTypeParameters {
  expression: string;
  cases: IGatewayCase[];
  default: IGatewayDefaultCase;
}

export type ServiceType = Record<ServicesEnum, string>;

export interface IServiceInterfaceType {
  type: ServicesEnum; // TODO check ServiceType above
  // target: string;
}

export type NodeLoggerData = {
  workspaceId: string;
  workflowId: string;
  instanceId: string;
  nodeId: string;
  duration?: number;
  debugId?: string;
  occurredAt: number;
  durationSinceStart?: number;
  debugInfo?: string;
};

export type NodeKPIData = {
  workspaceId: string;
  workflowId: string;
  instanceId: string;
  kpi: string;
  value: number;
  debugId?: string;
  occurredAt: number;
};
