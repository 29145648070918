import React from 'react';
import { styled, Theme } from '@mui/system';
// import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';

import { spacing } from '@material-ui/system';
import { yellow } from '@mui/material/colors';

import {
  Box as MuiBox,
  Chip,
  Drawer as MuiDrawer,
  ListItemButton,
  Tooltip,
} from '@mui/material';

import { ReactComponent as Logo } from '../../vendor/full-logo.svg';
import { SidebarItemsType } from '../../types/sidebar';
import Footer from './SidebarFooter';
import SidebarNav from './SidebarNav';
import useTheme from '../../hooks/useTheme';
import createTheme from '../../theme';

const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Brand = styled(ListItemButton) <{
  theme: any;
  component?: React.ReactNode;
  to?: string;
}>`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up('sm')} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const BrandIcon = styled(Logo) <{
  theme: any;
}>`
  margin-right: ${(props) => props.theme.spacing(2)};
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: 150px;
  // height: 32px;
`;

const BrandChip = styled(Chip)`
  background-color: ${yellow[900]};
  border-radius: 5px;
  color: ${(props) => props.theme.palette.common.white};
  font-size: 40%;
  height: 12px;
  margin-left: 2px;
  margin-top: -16px;
  padding: 0px 0;

  span {
    padding-left: ${(props) => props.theme.spacing(1.375)};
    padding-right: ${(props) => props.theme.spacing(1.375)};
  }
`;

export type SidebarProps = {
  PaperProps: {
    style: {
      width: number;
    };
  };
  variant?: 'permanent' | 'persistent' | 'temporary';
  open?: boolean;
  onClose?: () => void;
  items: {
    title: string;
    pages: SidebarItemsType[];
  }[];
  showFooter?: boolean;
};

const Sidebar: React.FC<SidebarProps> = ({
  items,
  showFooter = true,
  open,
  variant,
  onClose,
  PaperProps,

}) => {
  const { theme } = useTheme();
  const myTheme = createTheme(theme);
  console.log('theme Sidebar', myTheme);
  return (
    <Drawer variant={variant} open={open} onClose={onClose} PaperProps={PaperProps}>
      <Brand theme={myTheme} component={NavLink} to="/">
        <BrandIcon theme={myTheme} width="160" />
        {/* {' '} */}
        {/* <Box ml={1}> */}
        {/* Bitloops Console */}
        <Tooltip title="This is a pre-release alpha version and you will face bugs and potential data loss. Your feedback is extremely valued."><BrandChip label="Alpha" /></Tooltip>
        {/* </Box> */}
      </Brand>
      <SidebarNav items={items} />
      {!!showFooter && <Footer />}
    </Drawer>
  );
};

export default Sidebar;
