/* eslint-disable react/jsx-props-no-spreading */
// TODO remove eslint disable
import React, { forwardRef } from 'react';
// import styled from 'styled-components/macro';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { rgba, darken } from 'polished';
import { styled } from '@mui/system';

import {
  Chip,
  Collapse,
  ListItemProps,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import useTheme from '../../hooks/useTheme';
import createTheme from '../../theme';

const CustomRouterLink = forwardRef<any, NavLinkProps>((props, ref) => (
  <div ref={ref}>
    <NavLink {...props} />
  </div>
));

type ItemType = {
  theme: any;
  activeclassname?: string;
  onClick?: () => void;
  to?: string;
  component?: typeof NavLink;
  depth: number;
};

const Item = styled(ListItemButton)<ItemType>`
  padding-top: ${(props) => props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-bottom: ${(props) => props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-left: ${(props) => props.theme.spacing(props.depth && props.depth > 0 ? 14 : 8)};
  padding-right: ${(props) => props.theme.spacing(props.depth && props.depth > 0 ? 4 : 7)};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.08);
    color: ${(props) => props.theme.sidebar.color};
  }
  &.${(props) => props.activeclassname} {
    background-color: ${(props) => darken(0.03, props.theme.sidebar.background)};
    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

type TitleType = {
  depth: number;
  theme: any;
};

const Title = styled(ListItemText)<TitleType>`
  margin: 0;
  span {
    color: ${(props) => rgba(
    props.theme.sidebar.color,
    props.depth && props.depth > 0 ? 0.7 : 1,
  )};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)};
  }
`;

const Badge = styled(Chip)<
{ theme: any }
>`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 26px;
  top: 12px;
  background: ${(props) => props.theme.sidebar.badge.background};
  z-index: 1;
  span.MuiChip-label,
  span.MuiChip-label:hover {
    font-size: 11px;
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

const ExpandLessIcon = styled(ExpandLess)<
{ theme: any }
>`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const ExpandMoreIcon = styled(ExpandMore)<
{ theme: any }
>`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

type SidebarNavListItemProps = ListItemProps & {
  // className?: string;
  depth: number;
  href: string;
  icon: React.FC<any>;
  badge?: string;
  open?: boolean;
  title: string;
};

const SidebarNavListItem: React.FC<SidebarNavListItemProps> = (props): JSX.Element => {
  const {
    title,
    href,
    depth = 0,
    children,
    icon: Icon,
    badge,
    open: openProp = false,
  } = props;

  const theme = createTheme(useTheme().theme);
  const [open, setOpen] = React.useState(openProp);

  const handleToggle = (): void => {
    setOpen((state: any) => !state);
  };

  if (children) {
    return (
      <>
        <Item depth={depth} onClick={handleToggle} theme={theme}>
          {Icon && <Icon />}
          <Title depth={depth} theme={theme}>
            {title}
            {badge && <Badge label={badge} theme={theme} />}
          </Title>
          {open ? <ExpandLessIcon theme={theme} /> : <ExpandMoreIcon theme={theme} />}
        </Item>
        <Collapse in={open}>{children}</Collapse>
      </>
    );
  }
  return (
    <>
      <Item
        depth={depth}
        component={CustomRouterLink}
        to={href}
        activeclassname="active"
        theme={theme}
      >
        {Icon && <Icon />}
        <Title depth={depth} theme={theme}>
          {title}
          {badge && <Badge label={badge} theme={theme} />}
        </Title>
      </Item>
    </>
  );
};
SidebarNavListItem.defaultProps = {
  badge: '',
  open: false,
};

export default SidebarNavListItem;
