import {
  BookOpen,
  // PieChart,
  // Users,
  Layout,
  Server,
  Settings,
  Sliders,
  Cpu,
  Key,
} from 'react-feather';
import { SidebarItemsType } from '../../types/sidebar';

const pagesSection = [
  {
    href: '/dashboard',
    icon: Sliders,
    title: 'Dashboard',
  },
  {
    href: '/applications',
    icon: Layout,
    title: 'Applications',
  },
  // {
  //   href: '/analytics',
  //   icon: PieChart,
  //   title: 'Analytics',
  // },
  {
    href: '/workflows',
    icon: Cpu,
    title: 'Workflows',
  },
  {
    href: '/services',
    icon: Server,
    title: 'Services',
  },
  {
    href: '/secrets',
    icon: Key,
    title: 'Secrets',
  },
  // {
  //   href: '/authentication',
  //   icon: Users,
  //   title: 'Authentication',
  //   children: [
  //     {
  //       href: '/authentication/providers',
  //       title: 'Providers',
  //     },
  //   ],
  // },
  {
    href: '/settings',
    icon: Settings,
    title: 'Settings',
  },
] as SidebarItemsType[];

const helpSection = [
  {
    href: '/documentation',
    icon: BookOpen,
    title: 'Documentation',
  },
] as SidebarItemsType[];

const navItems = [
  {
    title: '',
    pages: pagesSection,
  },
  {
    title: 'Help & Getting Started',
    pages: helpSection,
  },
  // {
  //   title: 'Elements',
  //   pages: elementsSection,
  // },
  // {
  //   title: 'Documentation',
  //   pages: docsSection,
  // },
];

export default navItems;
