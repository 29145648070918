import React, { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';

import { StyledEngineProvider } from '@material-ui/styled-engine-sc';
import { ThemeProvider } from '@mui/material/styles';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import './i18n';
import createTheme from './theme';
import routes from './routes';

import useTheme from './hooks/useTheme';
import { getBitloops, store } from './redux/store';
import { userSignedIn } from './redux/slices/auth';
import { fetchWorkspaces } from './redux/slices/workspaces';

// const auth = getAuth();
// auth.useDeviceLanguage();

const App = (): JSX.Element => {
  const content = useRoutes(routes);
  const { theme } = useTheme();

  const bitloops = getBitloops();
  const { dispatch } = store;

  useEffect(() => {
    bitloops.auth.onAuthStateChanged((user) => {
      if (user) {
        console.log('bitloops authStateChanged!!', user);
        const {
          accessToken,
          displayName,
          email,
          emailVerified,
          isAnonymous,
          // metadata,
          photoURL,
          providerId,
          uid,
          refreshToken,

        } = user;
        const userData = {
          accessToken,
          displayName,
          email,
          emailVerified,
          isAnonymous,
          photoURL,
          providerId,
          uid,
          refreshToken,
          /** Remove first character if it's "/" */
          groupNames: (user?.jwt as any)?.group_names?.map((name:string) => name.replace(/^\//, '')),
        };
        dispatch(userSignedIn(userData));
        // if (user.email.split('@')[1] === 'bitloops.com'
        // || user.email === 'g.ntymenos@gmail.com') {
        dispatch(fetchWorkspaces());
        // }
      }// else {
      //     dispatch(userSignedOut());
      //   }
    });
  }, []);

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="%s | Bitloops Console"
        defaultTitle="Bitloops Console"
      />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={createTheme(theme)}>
            {content}
          </ThemeProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </HelmetProvider>
  );
};

export default App;
