import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import Bitloops from 'bitloops';
import { RootState } from '../store';
import actions from '../actions';

type UserData = {
    accessToken: string;
    displayName: string;
    email: string;
    emailVerified: boolean;
    isAnonymous: boolean;
    photoURL: string;
    providerId: string;
    uid: string;
    refreshToken: string;
    groupNames?: string[]
}

export interface IAuthState {
  userData: UserData| undefined;
  isAuthenticated: boolean;
  // bitloopsAuthReady: boolean
}

const localUserData = localStorage.getItem('auth.userData');
const initialState: IAuthState = {
  userData: localUserData !== null ? JSON.parse(localUserData) : undefined,
  isAuthenticated: false, // !!localStorage.getItem('auth.userData'),
  // bitloopsAuthReady: false,
};

// export const setBitloopsAuth = createAsyncThunk<any, {
//     accessToken: string,
//      }, AsyncThunkConfig>(
//        actions.auth.SET_AUTH,
//        async (payload, thunkArg) => {
//          const { accessToken } = payload;
//          console.log('auth set on Bitloops');
//        },
//      );

export const clearBitloopsAuth = createAsyncThunk(
  actions.auth.SIGNED_OUT,
  async (_, thunkArg: any) => {
    const bitloops: Bitloops = await thunkArg?.extra?.bitloops;
    bitloops.auth.clearAuthentication();
  },
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    userSignedIn(state:IAuthState, action: PayloadAction<UserData>) {
      state.userData = action.payload;
      state.isAuthenticated = true;
    },
    userSignedOut(state:IAuthState) {
      state.userData = undefined;
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(setBitloopsAuth.pending, (state: IAuthState) => {
    //   state.bitloopsAuthReady = false;
    // });
    // builder.addCase(setBitloopsAuth.fulfilled, (state: IAuthState) => {
    //   state.bitloopsAuthReady = true;
    // });
  },
});

export const { userSignedIn, userSignedOut } = authSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUserData = (state: RootState): UserData | undefined => {
  const { userData } = state.auth;
  return userData;
};
export const selectIsAuthenticated = (state: RootState): boolean => {
  const { isAuthenticated } = state.auth;
  return isAuthenticated;
};

export const selectAuth = (state: RootState): IAuthState => state.auth;
export default authSlice.reducer;
