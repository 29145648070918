import * as React from 'react';
import {
  Grid,
  Hidden,
  List,
  ListItemText as MuiListItemText,
  ListItemButtonProps as MuiListItemButtonProps,
  ListItemButton as MuiListItemButton,
} from '@mui/material';
import { styled } from '@mui/system';
import useTheme from '../hooks/useTheme';
import createTheme from '../theme';

interface ListItemButtonProps extends MuiListItemButtonProps {
  component?: string;
  href?: string;
  theme: any;
}

const Wrapper = styled('div')<
{ theme: any }
>`
  padding: ${(props) => props.theme.spacing(0.25)}
    ${(props) => props.theme.spacing(4)};
  background: ${(props) => props.theme.footer.background};
  position: relative;
`;

const ListItemButton = styled(MuiListItemButton)<ListItemButtonProps>`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)};
  padding-right: ${(props) => props.theme.spacing(2)};

  &,
  &:hover,
  &:active {
    color: #ff0000;
  }
`;

const ListItemText = styled(MuiListItemText)<
{ theme: any }
>`
  span {
    color: ${(props) => props.theme.footer.color};
  }
`;

const Footer = (): JSX.Element => {
  const theme = createTheme(useTheme().theme);
  return (
    <Wrapper theme={theme}>
      <Grid container spacing={0}>
        <Hidden mdDown>
          <Grid container item xs={12} md={6}>
            <List>
              <ListItemButton component="a" href="#" theme={theme}>
                <ListItemText primary="Support" theme={theme} />
              </ListItemButton>
              <ListItemButton component="a" href="#" theme={theme}>
                <ListItemText primary="Help Center" theme={theme} />
              </ListItemButton>
              <ListItemButton component="a" href="#" theme={theme}>
                <ListItemText primary="Privacy" theme={theme} />
              </ListItemButton>
              <ListItemButton component="a" href="#" theme={theme}>
                <ListItemText primary="Terms of Service" theme={theme} />
              </ListItemButton>
            </List>
          </Grid>
        </Hidden>
        <Grid container item xs={12} md={6} justifyContent="flex-end">
          <List>
            <ListItemButton theme={theme}>
              <ListItemText
                theme={theme}
                primary={`Copyright © ${new Date().getFullYear() > 2021 ? `2021 - ${new Date().getFullYear()}` : new Date().getFullYear()}. All rights reserved by Bitloops Ltd.`}
              />
            </ListItemButton>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default Footer;
