import React from 'react';
// import styled, { css } from 'styled-components/macro';
import { rgba } from 'polished';
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  Chip as MuiChip,
  Typography as MuiTypography,
  Tooltip,
} from '@mui/material';
import Box from '@mui/material/Box';

import { styled, spacing } from '@material-ui/system';
import useTheme from '../../hooks/useTheme';
import createTheme from '../../theme';

const Card = styled(MuiCard)<{ illustration?: string, theme: any }>`
  position: relative;
  margin-bottom: ${(props) => props.theme.spacing(6)};

  ${(props) => props.illustration &&
    props.theme.palette.mode !== 'dark' &&
    `
      background: ${(backgroundProps: any) => rgba(backgroundProps.theme.palette.primary.main, 0.125)};
      color: ${(colorProps: any) => colorProps.theme.palette.primary.main};
    `}
`;

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)<
{ theme: any }
>`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;

const Chip = styled(MuiChip)<
{ theme: any }
>`
  position: absolute;
  top: 16px;
  right: 16px;
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${(props) => props.theme.palette.secondary.main};
  color: ${(props) => props.theme.palette.common.white};
  margin-bottom: ${(props) => props.theme.spacing(4)};

  span {
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

// const Percentage = styled(MuiTypography)<{
//   percentagecolor: string;
//   illustration?: string;
// }>`
//   span {
//     color: ${(props) => props.percentagecolor};
//     font-weight: ${(props) => props.theme.typography.fontWeightBold};
//     background: ${(props) => rgba(props.percentagecolor, 0.1)};
//     padding: 2px;
//     border-radius: 3px;
//     margin-right: ${(props) => props.theme.spacing(2)};
//   }

//   ${(props) =>
//     props.illustration &&
//     props.theme.palette.mode !== 'dark' &&
//     css`
//       color: ${(props) => rgba(props.theme.palette.primary.main, 0.85)};
//     `}
// `;

const IllustrationImage = styled('img')<
{ theme: any }
>`
  height: 100px;
  position: absolute;
  right: ${(props) => props.theme.spacing(1)};
  bottom: ${(props) => props.theme.spacing(1)};
  display: none;

  ${(props) => props.theme.breakpoints.between('xs', 'lg')} {
    display: block;
  }

  @media (min-width: 1600px) {
    display: block;
  }
`;

type StatsProps = {
  title: string;
  titleTooltip?: string;
  value: string;
  valueColor?: string;
  chip?: string;
  // percentagetext?: string;
  // percentagecolor?: string;
  illustration?: string;
};

const defaultProps = {
  titleTooltip: undefined,
  valueColor: undefined,
  chip: undefined,
  illustration: undefined,
};

const Stats: React.FC<StatsProps> = ({
  title,
  titleTooltip,
  value,
  valueColor,
  chip,
  // percentagetext,
  // percentagecolor,
  illustration,
}): JSX.Element => {
  const theme = createTheme(useTheme().theme);

  return (
    <Card illustration={illustration} theme={theme}>
      <CardContent theme={theme}>

        <Typography variant="h6" mb={4}>
          {titleTooltip && <Tooltip title={titleTooltip} arrow><span>{title}</span></Tooltip>}
          {!titleTooltip && <span>{title}</span>}
        </Typography>

        <Typography variant="h1" mb={2}>
          <Box sx={{ textAlign: 'center' }} fontWeight="fontWeightRegular" color={valueColor}>{value}</Box>
        </Typography>
        {/* <Percentage
          variant="subtitle2"
          color="textSecondary"
          percentagecolor={percentagecolor}
          illustration={illustration}
        >
          <span>{percentagetext}</span> Since last month
        </Percentage> */}
        {!illustration && chip && <Chip label={chip} theme={theme} />}
      </CardContent>

      {!!illustration && (
      <IllustrationImage src={illustration} alt="Illustration" theme={theme} />
      )}
    </Card>
  );
};
Stats.defaultProps = defaultProps;

export default Stats;
