import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '../../redux/slices/auth';

// import useAuth from '../../hooks/useAuth';

interface AuthGuardType {
  children: React.ReactNode;
}

// For routes that can only be accessed by authenticated users
const AuthGuard = ({ children }: AuthGuardType): JSX.Element => {
  // const { isAuthenticated } = useAuth();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  // console.log('isAuthenticated', isAuthenticated);
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
};

export default AuthGuard;
