import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';

import {
  Button,
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from '@mui/material';
import { spacing } from '@material-ui/system';
import {
  Add as AddIcon,
} from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Table from '../dashboards/Default/Table';
import { selectWorkspaces } from '../../redux/slices/workspaces';
import NewWorkflowDialog from './newWorfklow';
import { selectApplications } from '../../redux/slices/applications';
import useAppDispatch from '../../hooks/useAppDispatch';
import { IApplication } from '../../definitions/application';
import { fetchWorkflows } from '../../redux/slices/workflows';

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

const Workflows = (): JSX.Element => {
  const { applicationId } = useParams();
  const applications = useSelector(selectApplications);
  const [application, setApplication] = useState<undefined | IApplication>(undefined);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchWorkflows(applicationId));
    if (
      applications &&
      applications.applicationsById &&
      applicationId &&
      applications.applicationsById.get(applicationId)
    ) {
      setApplication(applications.applicationsById.get(applicationId));
    }
  }, [applicationId, applications]);

  const workspaces = useSelector(selectWorkspaces);
  const ready = workspaces?.currentWorkspace !== undefined;
  return (
    <>
      <Helmet title={application ? `${application.name} Workflows` : 'Workflows'} />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Workflows
          </Typography>
          <Typography variant="subtitle1" />
        </Grid>
        <Grid item>
          <NewWorkflowDialog />
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid item xs={12} lg={6}>
        { ready ? (
          <Table
            title={`${workspaces.currentWorkspace?.name} Workflows`}
            application={application}
          />
        ) : <div>Loading...</div>}
      </Grid>
    </>
  );
};

export default Workflows;
