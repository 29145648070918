import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';

import {
  Button,
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from '@mui/material';
import { spacing } from '@material-ui/system';
import {
  Add as AddIcon,
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import Table from '../dashboards/Default/Table';
import { selectApplications, fetchApplications, ApplicationsStatuses } from '../../redux/slices/applications';
import useAppDispatch from '../../hooks/useAppDispatch';
import NewApplicationDialog from './newApplication';
import { IApplication } from '../../definitions/application';
import ApplicationCard from '../../components/applications/ApplicationCard';

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

const Applications = (): JSX.Element => {
  const applications = useSelector(selectApplications);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchApplications(null));
  }, []);

  return (
    <>
      <Helmet title="Applications" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Applications
          </Typography>
          <Typography variant="subtitle1" />
        </Grid>
        <Grid item>
          <NewApplicationDialog />
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={2}>
        { applications &&
          applications?.status === ApplicationsStatuses.OK &&
          applications?.applications ? (
            applications.applications.map(
              (application: IApplication) => (
                <Grid item xs={12} sm={6} lg={3}>
                  <ApplicationCard
                    key={application.id}
                    application={application}
                  />
                </Grid>
              ),
            )
          ) : <div>Loading...</div> }
      </Grid>
    </>
  );
};

export default Applications;
