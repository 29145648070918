import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AsyncThunkConfig, RootState } from '../store';
import actions from '../actions';
import { fetchWorkflows } from './workflows';

const LOCAL_STORAGE_KEY = 'workspaces.currentWorkspace';
interface IWorkspace {
  id: string;
  name: string;
}

export enum WorkspaceStatuses {
  LOADING = 'pending',
  OK = 'fulfilled',
  FAILED = 'rejected',
  LOGGED_OUT = 'logged out',
}

export interface IWorkspacesState {
  status: WorkspaceStatuses;
  workspaces?: IWorkspace[];
  currentWorkspace?: IWorkspace | null;
}

const localWorkspaceString = localStorage.getItem(LOCAL_STORAGE_KEY);
const localCurrentWorkspace =
  localWorkspaceString !== null ? JSON.parse(localWorkspaceString) : undefined;

const initialState: IWorkspacesState = {
  status: WorkspaceStatuses.LOADING,
  currentWorkspace: localCurrentWorkspace || undefined,
};

// const bitloopsConfig = {
//   apiKey: 'ewrewrew',
//   workspaceId: 'db24bb48-d2e3-4433-8fd0-79eef2bf63df',
//   messagingSenderId: 'e3rwewer',
//   server: 'localhost:3005',
//   ssl: false,
// };

export const fetchWorkspaces = createAsyncThunk<IWorkspace[], void, AsyncThunkConfig>(
  actions.workspaces.FETCHED_ALL,
  async (_, thunkArg) => {
    const bitloops = await thunkArg?.extra?.bitloops;
    const { auth } = thunkArg.getState();
    const groupNames = auth.userData?.groupNames;
    const uid = auth.userData?.uid;
    // const emailDomain = thunkArg.getState().auth.userData?.email?.split('@')[1];
    // const response = await bitloops.request('00cdd9c6-c6e9-40f2-b0c0-8903e33ef279',
    // '8214cbd7-4741-46a4-bb28-ae7ad0f33cc4', {
    //   emailDomain, // : 'free-now.com',
    // }); // 'workspaces.find'
    const response = await bitloops.request('d4c2dd47-dbf7-4b51-b5b0-62a0c5d0e986', 'da41f607-b3ac-4951-bdd5-957f746d6783', {
      uid,
      groupNames,
    }); // 'workspaces.find'
    console.log('fetching all workspaces res:', response);
    return response?.data;
  },
);

const idIsInArray = (id: string, data: IWorkspace[]): boolean => {
  for (let i = 0; i < data.length; i += 1) {
    if (id === data[i].id) return true;
  }
  return false;
};

const workspacesSlice = createSlice({
  name: 'workspaces',
  initialState,
  reducers: {
    workspaceChanged(state, action: PayloadAction<string>) {
      const newSelectedWorkspace = state?.workspaces?.find(
        (workspace) => workspace.id === action.payload,
      );
      if (newSelectedWorkspace !== undefined) {
        state.currentWorkspace = newSelectedWorkspace;
        /**
         * Throws a "QuotaExceededError" DOMException exception if the new value couldn't be set.
         * (Setting could fail if, e.g., the user has disabled storage for the site,
         *  or if the quota has been exceeded.)
         */
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newSelectedWorkspace));
      }
    },
  },
  extraReducers: (builder) => {
    // builder.addCase('auth/signedIn', (state, action) => {
    //   const status = WorkspaceStatuses.OK;
    //   const workspaces = action.payload;
    //   return { ...state, status, workspaces };
    // });
    builder.addCase(actions.auth.SIGNED_OUT, () => {
      console.log('clearing workspaces');
      return { status: WorkspaceStatuses.LOGGED_OUT };
    });
    builder.addCase(fetchWorkspaces.pending, (state) => {
      const status = WorkspaceStatuses.LOADING;
      return { ...state, status };
    });
    builder.addCase(fetchWorkspaces.rejected, (state) => {
      const status = WorkspaceStatuses.FAILED;
      return {
        ...state,
        status,
        workspaces: undefined,
        currentWorkspace: undefined,
      };
    });
    builder.addCase(fetchWorkspaces.fulfilled, (state, action) => {
      console.log('in fulfilled workspaces');
      const status = WorkspaceStatuses.OK;
      const workspaces = action.payload;
      let currentWorkspace;
      if (
        localCurrentWorkspace?.id &&
        idIsInArray(localCurrentWorkspace.id, action.payload)
      ) {
        currentWorkspace = localCurrentWorkspace;
      } else if (workspaces) {
        console.log('calling fetchWorkflows');
        const firstWorkspace = workspaces[0];
        currentWorkspace = firstWorkspace;
      }
      return {
        ...state,
        status,
        currentWorkspace,
        workspaces,
      };
    });
  },
});

export const { workspaceChanged } = workspacesSlice.actions;

export const selectWorkspaces = (state: RootState): IWorkspacesState => state.workspaces;
// export const { clearWorkspaces } = workspacesSlice.actions;
// export const { setWorkspaces, setCurrentWorkspace } = workspacesSlice.actions;
export default workspacesSlice.reducer;
