const actions = {
  auth: {
    SIGNED_OUT: 'auth/signedOut',
    SIGNED_IN: 'auth/signedIn',
    SET_AUTH: 'auth/setAuth',
  },
  applications: {
    FETCHED_MINE: 'applications/fetchMine',
  },
  workflows: {
    GOT_DRAFT_CALLBACK: 'workflow/draftCallback',
    CLEARED_DRAFT: 'workflow/clearedDraft',
    CREATED_NEW: 'workflows/createNew',
    FETCHED_ALL: 'workflows/fetchAll',
    FETCHED_BY_ID: 'workflows/fetchedById',
    UPDATED_META: 'workflows/updatedMeta',
    DEPLOY_WORKFLOW: 'workflow/deployWorkflow',
  },
  workspaces: {
    FETCHED_ALL: 'workspaces/FetchedAll',
  },
  ui: {
    NODE_PRESSED: 'node/pressed',
    TOOLBAR_NODE_PRESSED: 'toolbarNode/pressed',
    PANEL_X_PRESSED: 'panel/xPressed',
  },
  environments: {
    LOAD: 'environments/LOAD',
    CREATE: 'environments/CREATE',
    UPDATE: 'environments/UPDATE',
    REMOVE: 'environments/REMOVE',
  },
  secrets: {
    LOAD: 'secrets/LOAD',
    CREATE: 'secrets/CREATE',
    UPDATE: 'secrets/UPDATE',
    REMOVE: 'secrets/REMOVE',
  },
};

export default actions;
