import {
  createAction, createAsyncThunk, createSlice, current, PayloadAction,
} from '@reduxjs/toolkit';
import objectPath from 'object-path';
import { v4 as uuid } from 'uuid';

import { IBitloopsWorkflowDraftDefinition } from '../../definitions/workflow';
import actions from '../actions';
import { AppDispatch, AsyncThunkConfig, RootState } from '../store';
import { NodeTypeName } from '../../definitions/node';
import {
  getRequestStartNodeDefaultValues,
  getTaskNodeDefaultValues,
  getExclusiveNodeDefaultValues,
  getEndNodeDefaultValues,
  getCallActivityNodeDefaultValues,
  getDirectStartNodeDefaultValues,
  getMessageStartNodeDefaultValues,
  getTimerIntermediateNodeDefaultValues,
} from '../../components/editor/diagrams/Nodes';

export enum WorkflowStatuses {
  LOADING = 'pending',
  OK = 'fulfilled',
  FAILED = 'rejected',
  LOGGED_OUT = 'logged out',
  UPDATED = 'updated',
}

type PanelMetadata = {
  x: number,
  y: number,
  z: number,
  id: string,
}

export enum ElementType {
  NODE,
  EDGE,
}

export type SelectedElement = {
  type: ElementType,
  id: string,
}

type UIElements = {
  selectedElement: SelectedElement | null,
}

export interface IWorkflowState {
  status: WorkflowStatuses;
  workflow?: IBitloopsWorkflowDraftDefinition;
  panelArray?: PanelMetadata[];
  ui: UIElements;
}

const getWorkflowAbsPath = (
  wildcardPath: string,
  workflow: IBitloopsWorkflowDraftDefinition,
  nodeId?: string,
): string => {
  const words = wildcardPath.split('**');
  // console.log('getWorkflowAbsPath', wildcardPath, 'words', words);
  if (words.length === 1) return wildcardPath;
  if (words.length === 3) {
    // **node** or **edge**
    const type = words[1];
    if (type === 'node' && workflow.nodes) {
      for (let i = 0; i < workflow.nodes.length; i += 1) {
        if (workflow.nodes[i].id === nodeId) {
          return `nodes.${i}.${words[2]}`;
        }
      }
    } else if (type === 'edge' && workflow.edges) {
      for (let i = 0; i < workflow.edges.length; i += 1) {
        // TODO find edge abs path
        if (workflow.edges[i].from === nodeId) {
          return `edges.${i}.to.${words[2]}`;
        }
      }
    }
  }
  console.error('Wrong path provided');
  throw new Error('Wrong path provided');
};
const getInitialWorkflowValue = (): undefined | IBitloopsWorkflowDraftDefinition => undefined;
const getInitialPanelArrayValue =
  (workflow: IBitloopsWorkflowDraftDefinition | undefined): undefined | PanelMetadata[] => {
    if (workflow === undefined) return undefined;
    // const workflowPanelArray = {
    //   x: 10,
    //   y: 10,
    //   z: 10,
    //   id: 'workflow',
    // };
    return undefined;
    // return [workflowPanelArray];
  };
const getInitialSelectedNode = (): SelectedElement | null => null;

const initialState = {
  status: WorkflowStatuses.LOADING,
  workflow: getInitialWorkflowValue(),
  panelArray: getInitialPanelArrayValue(undefined),
  ui: {
    selectedElement: getInitialSelectedNode(),
  },
};

export const fetchWorkflowDraft = createAsyncThunk<any, any, {
  dispatch: AppDispatch, state: RootState
}>(
  'workflow/fetchDraft',
  async (workflowId, thunkArg: any) => {
    console.log('FETCHING WORKFLOW DRAFT data!', workflowId);
    const bitloops = await thunkArg?.extra?.bitloops;
    const workspaceId = thunkArg.getState().workspaces.currentWorkspace.id;
    const response = await bitloops.request(
      'dae0d6cb-7568-4356-b7c9-6050ae31c5b7',
      '60cfb497-b97d-4fea-831e-87b660ae03e6',
      {
        workspaceId,
        workflowId: 'c0a9aa27-684a-4ad7-8f87-27291f8e8bfe',
        nodeId: '33912fdf-0b23-42e1-8a75-722a8eb7b651',
        body: { workflowId },
      },
    ); // 'workflows.getCurrentDraft'
    // console.log('response workflow draft', response);
    return response;
  },
);

export const resetWorkflowDraft = createAsyncThunk<any, any, {
  dispatch: AppDispatch, state: RootState
}>(
  actions.workflows.CLEARED_DRAFT,
  async (workflowId, thunkArg: any) => {
    console.log('Clearing WORKFLOW DRAFT data!', workflowId);
    const bitloops = await thunkArg?.extra?.bitloops;
    const workspaceId = thunkArg.getState().workspaces.currentWorkspace.id;
    const response = await bitloops.request(
      'dae0d6cb-7568-4356-b7c9-6050ae31c5b7',
      '60cfb497-b97d-4fea-831e-87b660ae03e6',
      {
        workspaceId,
        workflowId: 'c0a9aa27-684a-4ad7-8f87-27291f8e8bfe',
        nodeId: '944a1849-62ea-40a1-b286-da56516c05da',
        body: { workflowId },
      },
    );
    return response;
  },
);

export const deployWorkflow = createAsyncThunk< any,
  {
    workflowId: string;
    workflow: any
  },
  AsyncThunkConfig>(
    actions.workflows.DEPLOY_WORKFLOW,
    async (data, thunkArg) => {
      const { workflowId, workflow } = data;
      const bitloops = await thunkArg?.extra?.bitloops;
      console.log('Completed await for bitloops', thunkArg?.getState());
      const payload = {
        updatedWorkflow: JSON.parse(JSON.stringify(workflow)),
        updatedVersion: 'v1',
        workflowId,
      };
      console.log('payload', payload);
      payload.updatedWorkflow.bitloopsEngineVersion = 'v0.0.1';
      // 'workflows.deployWorkflow'
      console.log('Deploying workflow!', workflowId, payload); // , 'thunkArg', thunkArg);
      const workspaceId = thunkArg?.getState()?.workspaces?.currentWorkspace?.id;
      const response = await bitloops.request(
        'dae0d6cb-7568-4356-b7c9-6050ae31c5b7',
        '60cfb497-b97d-4fea-831e-87b660ae03e6',
        {
          workspaceId,
          workflowId: '6f833ff4-f15b-4758-828e-45aec8843eaa',
          nodeId: '9b8b80fc-bfb6-4b79-8157-8e0be10e1859',
          body: payload,
        },
      );
      console.log('response workflow deploy', response);
      return response;
    },
  );

export const updateWorkflowDraft = createAsyncThunk<any, any, {
  dispatch: AppDispatch, state: RootState
}>(
  'workflow/updateWorkflowDraft',
  async (data, thunkArg: any) => {
    const {
      workflowId, updateData, updatePath, nodeId,
    } = data;
    // console.log('updateWorkflowDraft', 'inside update request for workflow draft', data);
    const bitloops = await thunkArg?.extra?.bitloops;
    let newDraft;
    // console.log('updateWorkflowDraft', 'updatePath', updatePath);
    if (updatePath) {
      newDraft = JSON.parse(JSON.stringify(thunkArg.getState().draftWorkflow.workflow));
      const absPath = getWorkflowAbsPath(updatePath, newDraft, nodeId);
      console.log('updateWorkflowDraft', 'absPath', absPath);
      objectPath.set(newDraft, absPath, updateData);
    } else newDraft = updateData;
    // eslint-disable-next-line max-len
    // console.log('updateWorkflowDraft', 'sending upd req for workflow draft', { workflowId, newDraft });
    const workspaceId = thunkArg?.getState()?.workspaces?.currentWorkspace?.id;
    const response = await bitloops.request(
      'dae0d6cb-7568-4356-b7c9-6050ae31c5b7',
      '60cfb497-b97d-4fea-831e-87b660ae03e6',
      {
        workspaceId,
        workflowId: 'fe4a2d30-cb29-427d-8d9a-bf4a9b0fb3b9',
        nodeId: '2a9f00c4-1cba-4309-9cb6-379c41d7ad16',
        body: { workflowId, newDraft },
      },
    );
    // console.log('response workflow draft update', response);
    return { response, newWorkflowDraft: newDraft };
  },
);

export const updateDelWorkflowDraft = createAsyncThunk<any, any, {
  dispatch: AppDispatch, state: RootState
}>(
  'workflow/updateDelWorkflowDraft',
  async (data, thunkArg: any) => {
    const {
      workflowId, updatePath, nodeId,
    } = data;
    const bitloops = await thunkArg?.extra?.bitloops;
    const newDraft = JSON.parse(JSON.stringify(thunkArg.getState().draftWorkflow.workflow));
    const absPath = getWorkflowAbsPath(updatePath, newDraft, nodeId);
    objectPath.del(newDraft, absPath);
    console.log('sending update request for workflow draft', { workflowId, newDraft });
    const workspaceId = thunkArg?.getState()?.workspaces?.currentWorkspace?.id;
    const response = await bitloops.request(
      'dae0d6cb-7568-4356-b7c9-6050ae31c5b7',
      '60cfb497-b97d-4fea-831e-87b660ae03e6',
      {
        workspaceId,
        workflowId: 'fe4a2d30-cb29-427d-8d9a-bf4a9b0fb3b9',
        nodeId: '2a9f00c4-1cba-4309-9cb6-379c41d7ad16',
        body: { workflowId, newDraft },
      },
    );
    console.log('response workflow draft update', response);
    return { response, newWorkflowDraft: newDraft };
  },
);

export const addNodeInWorkflowDraft = createAsyncThunk<any, any, {
  dispatch: AppDispatch, state: RootState
}>(
  'workflow/addNodeInWorkflowDraft',
  async (data, thunkArg: any) => {
    const {
      nodeType, nodeId,
    } = data;
    console.log('inside add node in workflow draft', data);
    const bitloops = await thunkArg?.extra?.bitloops;
    const newDraft = JSON.parse(JSON.stringify(thunkArg.getState().draftWorkflow.workflow));
    console.log('sending add node request', { workflowId: thunkArg.getState().draftWorkflow.workflow.id, newDraft });
    const workspaceId = thunkArg?.getState()?.workspaces?.currentWorkspace?.id;
    const response = await bitloops.request(
      'dae0d6cb-7568-4356-b7c9-6050ae31c5b7',
      '60cfb497-b97d-4fea-831e-87b660ae03e6',
      {
        workspaceId,
        workflowId: 'fe4a2d30-cb29-427d-8d9a-bf4a9b0fb3b9',
        nodeId: '2a9f00c4-1cba-4309-9cb6-379c41d7ad16',
        body: { workflowId: thunkArg.getState().draftWorkflow.workflow.id, newDraft },
      },
    );
    console.log('response workflow draft update', response);
    return { response, newWorkflowDraft: newDraft };
  },
);

const draftCallback = createAction(actions.workflows.GOT_DRAFT_CALLBACK);

export const listenForWorkflowDraftUpdates = createAsyncThunk<any, any, {
  dispatch: AppDispatch, state: RootState
}>(
  'workflow/listenForWorkflowDraftUpdates',
  async (data, thunkArg: any) => {
    // console.log('subscribing to event');
    const bitloops = await thunkArg?.extra?.bitloops;
    let message;
    await bitloops.subscribe('update', (eventData: any) => {
      console.log('callback listenForWorkflowDraftUpdates', eventData);
      // console.log(thunkArg);
      if (thunkArg.getState().draftWorkflow.id === eventData.id) {
        thunkArg.dispatch({
          type: actions.workflows.GOT_DRAFT_CALLBACK,
          payload: eventData,
        });
      }
    });
    return message;
  },
);

const workflowsSlice = createSlice({
  name: 'workflow',
  initialState,
  reducers: {
    elementSelected(state, action) {
      const nodeId = action.payload;
      state.ui.selectedElement = nodeId;
    },
    panelSelected(state, action) {
      state.ui.selectedElement = null;
    },
    // eslint-disable-next-line consistent-return
    nodePressed(state, action) {
      // console.log('action triggered', state.panelArray, action);
      if (!action.payload) return { ...state };
      const INITIAL_X = 10;
      const INITIAL_Y = 120;
      const INITIAL_Z = 10;
      const value = {
        id: action.payload, x: INITIAL_X, y: INITIAL_Y, z: INITIAL_Z,
      };
      let maxZ = INITIAL_Z;
      let maxX = INITIAL_X;
      let maxY = INITIAL_Y;
      let exists = -1;
      if (state.panelArray) {
        for (let i = 0; i < state.panelArray.length; i += 1) {
          if (state.panelArray[i].id === value.id) {
            exists = i;
          } else {
            if (state.panelArray[i].x > maxX) maxX = state.panelArray[i].x;
            if (state.panelArray[i].y > maxY) maxY = state.panelArray[i].y;
            if (state.panelArray[i].z > maxZ) maxZ = state.panelArray[i].z;
          }
        }
        if (exists > -1 && state.panelArray[exists].z < maxZ) {
          state.panelArray[exists].z = maxZ + 1;
        } else if (exists === -1) {
          value.z = maxZ + 1;
          value.x = maxX + 10;
          value.y = maxY + 10;
          state.panelArray.push(value);
        }
      } else {
        state.panelArray = [value];
      }
    },
    nodePressedX(state, action) {
      const id = action.payload;
      if (state.panelArray) {
        for (let i = 0; i < state.panelArray.length; i += 1) {
          if (state.panelArray[i].id === id) {
            state.panelArray.splice(i, 1);
          }
        }
      }
    },
    // eslint-disable-next-line consistent-return
    nodeChanged(state, action) {
      const { nodeId, path, value } = action.payload;
      // console.log('nodeChanged reducer', nodeId, path, value);
      if (!state.workflow) return state;
      const absPath = getWorkflowAbsPath(path, state.workflow, nodeId);
      const newDraft = JSON.parse(JSON.stringify(state.workflow));
      objectPath.set(newDraft, absPath, value);
      state.workflow = newDraft;
    },
    nodeDragged(state, action) {
      const { nodeId, x, y } = action.payload;
      if (state.panelArray) {
        for (let i = 0; i < state.panelArray.length; i += 1) {
          if (state.panelArray[i].id === nodeId) {
            state.panelArray[i].x = x;
            state.panelArray[i].y = y;
          }
        }
      }
    },
    nodesRemoved(state, action) {
      if (state.workflow) state.workflow.nodes = action.payload;
    },
    edgesRemoved(state, action) {
      console.log('edgesRemoved', action.payload);
      if (state.workflow) state.workflow.edges = action.payload;
    },
    workflowDraftUpdated(state, action) {
      state.workflow = action.payload;
    },
    nodeAdded(state, action) {
      const { nodeId, nodeType, position } = action.payload;
      const workflowDraft = JSON.parse(JSON.stringify(state.workflow));
      let newNode;

      switch (nodeType) {
        case NodeTypeName.RequestStartNode:
          newNode = getRequestStartNodeDefaultValues(nodeId);
          break;
        case NodeTypeName.MessageStartNode:
          newNode = getMessageStartNodeDefaultValues(nodeId);
          break;
        case NodeTypeName.DirectStartNode:
          newNode = getDirectStartNodeDefaultValues(nodeId);
          break;
        case NodeTypeName.TaskNode:
          newNode = getTaskNodeDefaultValues(nodeId);
          break;
        case NodeTypeName.CallNode:
          newNode = getCallActivityNodeDefaultValues(nodeId);
          break;
        case NodeTypeName.ExclusiveNode:
          newNode = getExclusiveNodeDefaultValues(nodeId);
          break;
        case NodeTypeName.EndNode:
          newNode = getEndNodeDefaultValues(nodeId);
          break;
        case NodeTypeName.TimerIntermediateNode:
          newNode = getTimerIntermediateNodeDefaultValues(nodeId);
          break;
        default: {
          newNode = getTaskNodeDefaultValues(nodeId);
        }
      }
      if (!workflowDraft.nodes) workflowDraft.nodes = [];
      workflowDraft.nodes.push({ ...newNode, visual: position });
      return { ...state, workflow: workflowDraft };
    },
    [actions.auth.SIGNED_OUT]: () => ({
      status: WorkflowStatuses.LOGGED_OUT,
      workflow: undefined,
      panelArray: undefined,
      ui: initialState.ui,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(draftCallback, (state, action: any) => {
      console.log('Callback REDUCER RUN!', action);
      const { eventData } = action.payload;
      if (action.payload && state?.workflow?.id === eventData?.id) {
        return {
          ...state,
          status: WorkflowStatuses.UPDATED,
          workflow: action.payload.eventData,
        };
      }
      console.log('update for other workflow received');

      return state;
      // { status: WorkflowStatuses.OK, workflow: }
    });
    // fetchWorkflowDrafts
    builder.addCase(fetchWorkflowDraft.pending, (state) => {
      const status = WorkflowStatuses.LOADING;
      return { ...state, status };
    });
    builder.addCase(fetchWorkflowDraft.rejected, (state) => {
      const status = WorkflowStatuses.FAILED;
      return {
        ...state,
        status,
        workflow: undefined,
      };
    });
    builder.addCase(fetchWorkflowDraft.fulfilled, (state, action) => {
      console.log('[draftWorkflow - fetchWorkflowDraft.fullfilled]', action.payload.response);
      const status = WorkflowStatuses.OK;
      const workflow = action.payload.response;
      return {
        ...state,
        status,
        workflow: { ...workflow },
        panelArray: getInitialPanelArrayValue(workflow),
      };
    });
    builder.addCase(resetWorkflowDraft.pending, (state) => {
      const status = WorkflowStatuses.LOADING;
      return { ...state, status };
    });
    builder.addCase(resetWorkflowDraft.rejected, (state) => {
      console.log('[draftWorkflow - resetWorkflowDraft.rejected]');
      const status = WorkflowStatuses.FAILED;
      return {
        ...state,
        status,
        workflow: undefined,
      };
    });
    builder.addCase(resetWorkflowDraft.fulfilled, (state, action) => {
      console.log('[draftWorkflow - resetWorkflowDraft.fullfilled]', action.payload.response);
      const status = WorkflowStatuses.OK;
      const workflow = action.payload.response;
      return {
        ...state,
        status,
        workflow: { ...workflow },
        panelArray: getInitialPanelArrayValue(workflow),
      };
    });

    // updateWorkflowDraft
    builder.addCase(updateWorkflowDraft.pending, (state) => {
      const status = WorkflowStatuses.LOADING;
      console.log('Updating draft workflow...');
      return { ...state, status };
    });
    builder.addCase(updateWorkflowDraft.rejected, (state) => {
      console.error('Updated rejected!');
      // TODO get old workflow draft here
      // const status = WorkflowsStatuses.FAILED;
      // return {
      //   ...state,
      //   status,
      //   workflow: undefined,
      // };
    });
    builder.addCase(updateWorkflowDraft.fulfilled, (state, action) => {
      // console.log('SAVED!!!', action.payload);
      const status = WorkflowStatuses.OK;
      // const workflow = action.payload;
      return {
        ...state,
        status,
        workflow: action.payload.newWorkflowDraft,
      };
    });
    builder.addCase(updateDelWorkflowDraft.pending, (state) => {
      const status = WorkflowStatuses.LOADING;
      console.log('Updating draft workflow...');
      return { ...state, status };
    });
    builder.addCase(updateDelWorkflowDraft.rejected, (state) => {
      console.error('Updated rejected!');
    });
    builder.addCase(updateDelWorkflowDraft.fulfilled, (state, action) => {
      console.log('Updated draft workflow...');
      const status = WorkflowStatuses.OK;
      return {
        ...state,
        status,
        workflow: action.payload.newWorkflowDraft,
      };
    });
    builder.addCase(listenForWorkflowDraftUpdates.fulfilled, (state, action) => {
      console.log('Got event!!!', action.payload);
      const status = WorkflowStatuses.OK;
      const workflow = action.payload;
      return {
        ...state,
        status,
        // workflow: action.payload.newWorkflowDraft,
      };
    });
  },
});

export const selectWorkflowDraft = (state: RootState): IWorkflowState | undefined => {
  // eslint-disable-next-line no-param-reassign
  if (state.draftWorkflow) return state.draftWorkflow;
  return undefined;
};

export const selectDraftWorkflow = (state: RootState): IWorkflowState => state.draftWorkflow;

export const selectSelectedElement = (state: RootState): SelectedElement | null => {
  const { selectedElement } = state.draftWorkflow.ui;
  if (selectedElement) return selectedElement;
  return null;
};

export const {
  nodesRemoved,
  nodeAdded,
  nodePressed,
  nodePressedX,
  nodeChanged,
  nodeDragged,
  edgesRemoved,
  workflowDraftUpdated,
  elementSelected,
  panelSelected,
} = workflowsSlice.actions;

export default workflowsSlice.reducer;
