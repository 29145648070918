/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import styled, { css } from 'styled-components/macro';
import { rgba } from 'polished';
import {
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardMedia,
  Typography as MuiTypography,
} from '@mui/material';
import { spacing } from '@material-ui/system';
import { useNavigate } from 'react-router-dom';

import { IApplication } from '../../definitions/application';
import applicationImage from '../../assets/workflow.png';
import './applicationCard.css';

const Typography = styled(MuiTypography)(spacing);

const Card = styled(MuiCard)<{ illustration?: string }>`
  position: relative;
  height: 340px;
  cursor: pointer;
  ${(props) => props.illustration &&
    props.theme.palette.mode !== 'dark' &&
    css`
      background: ${(props) => rgba(props.theme.palette.primary.main, 0.125)};
      color: ${(props) => props.theme.palette.primary.main};
    `}
`;

const CardContent = styled(MuiCardContent)`
  position: relative;
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;

interface IApplicationCardProps {
  application: IApplication;
}

const ApplicationCard: React.FC<IApplicationCardProps> = (props): JSX.Element => {
  const { application } = props;
  const navigate = useNavigate();

  return (
    <Card onClick={() => { navigate(`/applications/${application.id}`); }}>
      <CardContent>
        { (application.meta && application.meta.previewImage) ? (
          <CardMedia
            component="img"
            height="140"
            image={application.meta.previewImage}
            alt="logo"
          />
        )
          : (
            <CardMedia
              component="img"
              height="140"
              image={applicationImage}
              alt="logo"
            />
          )}
        <div className="application-name">
          <Typography variant="h3" mb={6}>
            {application.name}
          </Typography>
        </div>
        <div className="application-description">
          <Typography variant="h6" mb={6}>
            <Box fontWeight="fontWeightRegular">
              { application.description }
            </Box>
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

export default ApplicationCard;
