import React, { useState } from 'react';
// import styled from 'styled-components/macro';
import { Outlet } from 'react-router-dom';
import { Hidden, CssBaseline, Paper as MuiPaper } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
// import { styled, spacing } from '@material-ui/system';
import { useSelector } from 'react-redux';
import { styled, spacing, Theme } from '@mui/system';
import Navbar from '../components/navbar/Navbar';
import dashboardItems from '../components/sidebar/dashboardItems';
import Sidebar from '../components/sidebar/Sidebar';
import Footer from '../components/Footer';
import { selectIsAuthenticated } from '../redux/slices/auth';
import useTheme from '../hooks/useTheme';
import createTheme from '../theme';
import { selectWorkspaces } from '../redux/slices/workspaces';

const drawerWidth = 258;

const Root = styled('div')`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled('div')<
{ theme: Theme }
>`
  ${(props) => props.theme.breakpoints.up('md')} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)<
{ theme: Theme }
>`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard: React.FC = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const workspaces = useSelector(selectWorkspaces);
  const handleDrawerToggle = (): void => {
    setMobileOpen(!mobileOpen);
  };

  const theme = createTheme(useTheme().theme);
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const isAuthenticated = useSelector(selectIsAuthenticated);

  return (
    <Root>
      <CssBaseline />
      {isAuthenticated &&
          workspaces?.currentWorkspace &&
          (
          <Drawer theme={theme}>
            <Hidden lgUp implementation="js">
              <Sidebar
                PaperProps={{ style: { width: drawerWidth } }}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                items={dashboardItems}
              />
            </Hidden>
            <Hidden mdDown implementation="css">
              <Sidebar
                PaperProps={{ style: { width: drawerWidth } }}
                variant="permanent"
                items={dashboardItems}
              />
            </Hidden>
          </Drawer>
          )}
      <AppContent>
        {isAuthenticated &&
          (<Navbar theme={theme} onDrawerToggle={handleDrawerToggle} />)}
        <MainContent theme={theme} p={isLgUp ? 5 : 5}>
          {children}
          <Outlet />
        </MainContent>
        <Footer />
      </AppContent>
    </Root>
  );
};

export default Dashboard;
