import React from 'react';
import { Helmet } from 'react-helmet-async';
import { grey } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import { styled } from '@material-ui/system';

// import {
//   Add as AddIcon,
// } from '@mui/icons-material';
// import { useSelector } from 'react-redux';
// import Table from '../dashboards/Default/Table';
// import { selectWorkspaces } from '../../redux/slices/workspaces';
// import Diagraphs from './Digraphs';
// import Tools from './Tools'
// import NodePanel from '../../components/nodes/NodePanels';
// import { green, red } from '@mui/material/colors';

// import Actions from './Actions';
// import Stats from '../../../components/analytics/Stats';
// import Table from './Table';

// const Divider = styled(MuiDivider)(spacing);

// const Typography = styled(MuiTypography)(spacing);
import THEMES from '../constants';
import useTheme from '../hooks/useTheme';
import createTheme from '../theme';

type DemoButtonType = {
  theme: any;
  active?: boolean;
};

const DemoButton = styled('div')<DemoButtonType>`
  cursor: pointer;
  background: ${(props) => props.theme.palette.background.paper};
  height: 80px;
  border-radius: 0.3rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.825rem;
  position: relative;
  border: 1px solid
    ${(props) => (!props.active ?
    props.theme.palette.action.selected
    : props.theme.palette.action.active)};
`;

type DemoButtonInnerType = {
  theme: any;
  selectedTheme: string;
};

const DemoButtonInner = styled('div')<DemoButtonInnerType>`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 0 0 1px ${(props) => props.theme.palette.action.selected};
  position: relative;

  ${(props) => props.selectedTheme === THEMES.DEFAULT &&
    `
      background: linear-gradient(-45deg, #23303f 50%, ${grey[100]} 0);
    `}
  ${(props) => props.selectedTheme === THEMES.DARK &&
    `
      background: #23303f;
    `}
`;

const DemoTitle = styled(Typography)`
  text-align: center;
`;

const Heading = styled(ListItemButton)<{
  theme: any
}>`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;

  ${(props) => props.theme.breakpoints.up('sm')} {
    min-height: 64px;
  }
`;

type DemoTypes = {
  title: string;
  themeVariant: string;
};

const Demo = ({ title, themeVariant }: DemoTypes): JSX.Element => {
  const { theme, setTheme } = useTheme();
  return (
    <Grid item xs={6}>
      <DemoButton
        theme={createTheme(theme)}
        active={themeVariant === theme}
        onClick={() => setTheme(themeVariant)}
      >
        <DemoButtonInner theme={createTheme(theme)} selectedTheme={themeVariant} />
      </DemoButton>
      <DemoTitle variant="subtitle2" gutterBottom>
        {title}
      </DemoTitle>
    </Grid>
  );
};

const Settings = (): JSX.Element => {
  const myTheme = createTheme(useTheme().theme);
  return (
    <>
      <Helmet title="Settings" />
      <Heading theme={myTheme}>Select a theme</Heading>
      <Box px={4} my={3}>
        <Grid container spacing={3}>
          <Demo title="Dark" themeVariant={THEMES.DARK} />
          <Demo title="Default" themeVariant={THEMES.DEFAULT} />
        </Grid>
      </Box>
    </>
  );
};

export default Settings;
