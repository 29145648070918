import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IEnvironment } from '../../definitions/services';
import actions from '../actions';
import {
  AsyncThunkConfig, RootState,
} from '../store';

// const mockEnvironments: any = {
//   production: {
//     name: 'Production',
//     description: 'Production environment',
//     workspaceId: 'db24bb48-d2e3-4433-8fd0-79eef2bf63df',
//   },
//   development: {
//     name: 'Development',
//     description: 'Development environment',
//     workspaceId: 'db24bb48-d2e3-4433-8fd0-79eef2bf63df',
//   },
// };

export interface IEnvironmentsState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  environments: Record<string, IEnvironment>;
  error: string | null
}

const initialState: IEnvironmentsState = {
  status: 'idle',
  environments: {},
  error: null,
};

export const fetchEnvironments = createAsyncThunk<
   Record<string, IEnvironment>, void, AsyncThunkConfig>(
     actions.environments.LOAD,
     async (_, thunkApi) => {
       const { bitloops } = thunkApi.extra;
       const workspaceId = thunkApi.getState()?.workspaces?.currentWorkspace?.id;
       const response = await bitloops.request(
         'dae0d6cb-7568-4356-b7c9-6050ae31c5b7',
         '60cfb497-b97d-4fea-831e-87b660ae03e6',
         {
           workspaceId,
           workflowId: '32480081-cff8-4739-afcb-9a2fb945141f',
           nodeId: '9034fdcd-eca0-4129-a16c-ae484b4347db',
           body: { workspaceId },
         },
       ) as any[];
       return response.reduce((prevValue, currValue) => (
         { ...prevValue, [currValue.id]: currValue }
       ),
       {});
     },
   );

export const addNewEnvironment = createAsyncThunk<any, any, AsyncThunkConfig >(
  actions.environments.CREATE,
  async (payload, thunkApi) => {
    // pass
  },
);

export const removeEnvironment = createAsyncThunk<any, string, AsyncThunkConfig>(
  actions.environments.REMOVE,
  async (payload, thunkApi) => {
    const id = payload;
    const bitloops = await thunkApi?.extra?.bitloops;
    const workspaceId = thunkApi.getState().workspaces?.currentWorkspace?.id;
    // pass
  },
);

const environmentsSlice = createSlice({
  name: 'environments',
  initialState,
  reducers: {
    environmentAdded(state, action: PayloadAction<Partial<IEnvironment>>) {
      const newEnvironment = action.payload;
      // state.environments[newEnvironment.id] = newEnvironment;
    },
    environmentRemoved(state, action: PayloadAction<string>) {
      const environmentId = action.payload;
      // delete state.services[serviceId];
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEnvironments.pending, (state) => {
      const status = 'loading';
      state.status = status;
    });
    builder.addCase(fetchEnvironments.fulfilled, (
      state,
      action: PayloadAction<Record<string, IEnvironment>>,
    ) => {
      console.log('fetch environments fulfilled', action.payload);
      state.status = 'succeeded';
      state.environments = action.payload;
    });
    builder.addCase(fetchEnvironments.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(addNewEnvironment.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(addNewEnvironment.fulfilled, (state, action) => {
      console.log('addService fullfiled', action.payload);
      state.status = 'succeeded';
    });
    builder.addCase(addNewEnvironment.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(removeEnvironment.pending, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(removeEnvironment.fulfilled, (state, action) => {
      state.status = 'succeeded';
    });
    builder.addCase(removeEnvironment.rejected, (state, action) => {
      state.status = 'failed';
    });
  },
});

export const {
  environmentAdded,
  environmentRemoved,
  reset: resetEnvironments,
} = environmentsSlice.actions;

export const selectEnvironments = (state: RootState): IEnvironmentsState => state.environments;

export default environmentsSlice.reducer;
