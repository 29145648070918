import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IBitloopsWorkflowDefinition } from '../../definitions/workflow';
import { IApplication } from '../../definitions/application';
import actions from '../actions';
import { AppDispatch, RootState } from '../store';

export enum ApplicationsStatuses {
  PENDING = 'pending',
  OK = 'fulfilled',
  FAILED = 'rejected',
  LOGGED_OUT = 'logged out',
  IDLE = 'idle',
}

export interface IApplicationsState {
  status: ApplicationsStatuses;
  applications?: IApplication[];
  applicationsById?: Map<string, IApplication>;
}

const initialState = {
  status: ApplicationsStatuses.IDLE,
  applications: undefined,
  applicationsById: new Map<string, IApplication>(),
};

export const createApplication = createAsyncThunk<any, any, {
  dispatch: AppDispatch, state: RootState
}>(
  actions.workflows.CREATED_NEW,
  async (data: { applicationId: string, name: string, description: string }, thunkArg: any) => {
    const { applicationId, name, description } = data;
    const workspaceId = thunkArg.getState().workspaces.currentWorkspace.id;
    const bitloops = await thunkArg?.extra?.bitloops;
    const { uid } = bitloops.auth.userData;
    const application: IApplication = {
      id: applicationId,
      name,
      description,
      workspace_id: workspaceId,
      iam: {
        users: {
          [uid]: {
            permissions: ['owner'],
          },
        },
      },
    };
    const response = await bitloops.request(
      'dae0d6cb-7568-4356-b7c9-6050ae31c5b7',
      '60cfb497-b97d-4fea-831e-87b660ae03e6',
      {
        workspaceId,
        workflowId: '817c0b6b-65a6-452c-88db-bf2b5a6d4030',
        nodeId: '6bc4ec4f-5a79-4515-963f-00e98c5f9bb8',
        body: { application },
      },
    );
    return response;
  },
);

export const fetchApplications = createAsyncThunk<any, any, {
  dispatch: AppDispatch, state: RootState
}>(
  actions.applications.FETCHED_MINE,
  async (_, thunkArg: any) => {
    // console.log('FETCHING WORKFLOWS!', thunkArg?.extra?.bitloops);
    const bitloops = await thunkArg?.extra?.bitloops;
    // console.log('Completed await for bitloops', thunkArg?.getState());
    const workspaceId = thunkArg.getState().workspaces.currentWorkspace.id;
    console.log('sending applications request', workspaceId);
    const response = await bitloops.request(
      'dae0d6cb-7568-4356-b7c9-6050ae31c5b7',
      '60cfb497-b97d-4fea-831e-87b660ae03e6',
      {
        workspaceId,
        workflowId: '929ba986-0262-409f-ade3-4a44083228d8',
        nodeId: 'b6bdd09e-a819-43a4-942f-4328fb66fb0c',
        body: { workspaceId },
      },
    );
    console.log('response applications', response);
    return response;
  },
);

export const updateWorkflowMeta = createAsyncThunk<any, any, {
  dispatch: AppDispatch, state: RootState
}>(
  actions.workflows.UPDATED_META,
  async (data, thunkArg: any) => {
    const { workflowId, updateData } = data;
    const bitloops = await thunkArg?.extra?.bitloops;
    console.log('sending update request for workflow meta', data);
    const response = await bitloops.request(
      'c9662a54-11c6-4243-a003-4541dea20b9f', '69048293-4200-4a8d-9c7d-b8d773cea76a', { entity: 'workflows', entityId: workflowId, updateData }, // 'workflows.updateEntityById'
    );
    console.log('response workflows', response);
    return response;
  },
);

const applicationsSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    [actions.auth.SIGNED_OUT]: () => ({
      status: ApplicationsStatuses.LOGGED_OUT,
      applications: undefined,
      applicationsById: new Map<string, IApplication>(),
    }),
  },
  extraReducers: (builder) => {
    // fetchWorkflowById
    // builder.addCase(fetchWorkflow.fulfilled, (state, action) => {
    //   const status = WorkflowsStatuses.OK;
    //   const workflow: IBitloopsWorkflowDefinition = action.payload;
    //   const { workflowsById } = state;
    //   const newWorkflowById = JSON.parse(JSON.stringify(workflowsById));
    //   if (workflowsById) newWorkflowById[workflow.id] = workflow;
    //   return {
    //     ...state,
    //     status,
    //     workflowsById,
    //   };
    // });
    // fetchWorkflows
    builder.addCase(fetchApplications.pending, (state) => {
      const status = ApplicationsStatuses.PENDING;
      return { ...state, status };
    });
    builder.addCase(fetchApplications.rejected, (state) => {
      const status = ApplicationsStatuses.FAILED;
      return {
        ...state,
        status,
        applications: undefined,
      };
    });
    builder.addCase(fetchApplications.fulfilled, (state, action) => {
      const status = ApplicationsStatuses.OK;
      const applications = action.payload.response;
      const applicationsById = new Map<string, IApplication>();
      // TODO here when error type is object. Better solution would be
      // to have data, error in action.payload
      if (applications && Array.isArray(applications)) {
        applications?.forEach(
          (application: IApplication) => applicationsById.set(application.id, application),
        );
      } else {
        console.error('Error: Applications fetched are undefined');
      }
      return {
        ...state,
        status,
        applications,
        applicationsById,
      };
    });
    // updateWorkflowMeta
    builder.addCase(updateWorkflowMeta.pending, (state) => {
      // const status = WorkflowsStatuses.LOADING;
      // return { ...state, status };
      console.log('pending meta update');
    });
    builder.addCase(updateWorkflowMeta.rejected, (state) => {
      console.error('Update workflowMeta rejected!');
      // const status = WorkflowsStatuses.FAILED;
      // return {
      //   ...state,
      //   status,
      //   workflow: undefined,
      // };
    });
    builder.addCase(updateWorkflowMeta.fulfilled, (state, action) => {
      console.log('SAVED!!!', action.payload);
      // const status = WorkflowsStatuses.OK;
      // const workflows = action.payload;
      // return {
      //   ...state,
      //   status,
      //   workflows,
      // };
    });
  },
});

export const selectApplications = (state: RootState): IApplicationsState | undefined => {
  if (state.applications) return state.applications;
  return undefined;
};

export const selectApplicationById =
(s: IApplicationsState, id: string): IApplication | undefined => {
  if (s.applications) {
    return s.applications.find(((application) => application.id === id));
  }
  return undefined;
};

// export const { variablesUpdated } = applicationsSlice.actions;

export default applicationsSlice.reducer;
