import React, { FC, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { ContentCopy as ContentCopyIcon, DoneOutline as DoneOutlineIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';

interface CopyToClipboardProps {
  title: string;
  value: string;
  doneText: string;
}

const CopyToClipboard: FC<CopyToClipboardProps> = (props) => {
  const { title, value, doneText } = props;
  const [clipboardValue, setClipboardValue] = useState('');
  const copyWorkflowIdToClipboard = ():void => {
    if (value) {
      navigator.clipboard.writeText(value);
      setClipboardValue(value);
      setTimeout(() => {
        setClipboardValue('');
      }, 3000);
    }
  };
  return (
    <Tooltip title={title}>
      {clipboardValue === value ? (
        <span style={{ color: 'green', margin: '10px' }}>
          <DoneOutlineIcon />
          {doneText}
        </span>
      ) : (
        <IconButton
          aria-label="Copy id to Clipboard"
          size="large"
          onClick={copyWorkflowIdToClipboard}
          onKeyPress={copyWorkflowIdToClipboard}
        >
          <ContentCopyIcon />
        </IconButton>
      )}
    </Tooltip>
  );
};

export default CopyToClipboard;
