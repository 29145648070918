import * as React from 'react';

import Loader from './Loader';

const sleep = (m: number): Promise<void> => new Promise((r) => setTimeout(r, m));

type componentType = React.ComponentClass<any> | null;

interface IAsyncState {
  component: componentType;
}

const asyncComponent = (importComponent: any): any => {
  class AsyncComponent extends React.Component<any, IAsyncState> {
    constructor(props: any) {
      super(props);

      this.state = {
        component: null,
      };
    }

    async componentDidMount(): Promise<void> {
      await sleep(process.env.NODE_ENV === 'development' ? 150 : 0);

      const { default: component } = await importComponent();

      this.setState({
        component,
      });
    }

    render(): JSX.Element {
      const { component } = this.state;
      const C: componentType = component;

      // TODO see how we can fix this and remove eslint-disable
      // eslint-disable-next-line react/jsx-props-no-spreading
      return C ? <C {...this.props} /> : <Loader />;
    }
  }

  return AsyncComponent;
};

export default asyncComponent;
