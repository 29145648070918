import React from 'react';
import async from './components/Async';

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from './layouts/Auth';
import DashboardLayout from './layouts/Dashboard';
import WelcomeLayout from './layouts/Welcome';
import NewWorkspaceLayout from './layouts/NewWorkspace';

// Guards
import AuthGuard from './components/guards/AuthGuard';

import Page404 from './pages/auth/Page404';
import Documentation from './pages/help/Documentation';
import Settings from './pages/Settings';

// Protected routes
import ProtectedPage from './pages/protected/ProtectedPage';
import Login from './pages/auth/Login';
import Applications from './pages/applications';
import Workflows from './pages/workflows';
// import AuthProvider from './pages/AuthProvider';
import Services from './pages/Services';
import Secrets from './pages/secrets';

// const Application = async(() => import('./pages/applications/application/Application'));
const Workflow = async(() => import('./pages/workflows/workflow/workflow'));
// const Workflows = async(() => import('./pages/workflows'));
const Welcome = async(() => import('./pages/dashboards/Welcome'));

// Dashboard components
const Default = async(() => import('./pages/dashboards/Default'));
const Analytics = async(() => import('./pages/dashboards/Analytics'));

const routes = [
  {
    path: 'login',
    element: <AuthLayout />,
    children: [
      {
        path: '',
        element: <Login />,
      },
    ],
  },
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      {
        path: '',
        element: <AuthGuard><Default /></AuthGuard>,
        // element: <Default />,
      },
    ],
  },
  {
    path: 'dashboard',
    element: <DashboardLayout />,
    children: [
      {
        path: '',
        element: <AuthGuard><Default /></AuthGuard>,
      },
    ],
  },
  {
    path: 'applications',
    element: <DashboardLayout />,
    children: [
      {
        path: '',
        element: (
          <AuthGuard>
            <Applications />
          </AuthGuard>
        ),
      },
      {
        path: ':applicationId',
        element: (
          <AuthGuard>
            <Workflows />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: 'welcome',
    element: <WelcomeLayout />,
    children: [
      {
        path: '',
        element: (<Welcome />
        // <AuthGuard>
        //   <Welcome />
        // </AuthGuard>
        ),
      },
    ],
  },
  {
    path: 'new-workspace',
    element: <NewWorkspaceLayout />,
    children: [
      {
        path: '',
        element: (
          <AuthGuard>
            <Welcome />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: 'workflows',
    element: <DashboardLayout />,
    children: [
      {
        path: ':workflowId',
        element: (
        // <AuthGuard>
          <Workflow />
        // </AuthGuard>
        ),
      },
      {
        path: '',
        element: (
          <AuthGuard>
            <Workflows />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: 'analytics',
    element: <DashboardLayout />,
    children: [
      {
        path: '',
        element: (
          <AuthGuard>
            <Analytics />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: 'services',
    element: <DashboardLayout />,
    children: [
      {
        path: '',
        element: (
          <AuthGuard>
            <Services />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: 'secrets',
    element: <DashboardLayout />,
    children: [
      {
        path: '',
        element: (
          <AuthGuard>
            <Secrets />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: 'settings',
    element: <DashboardLayout />,
    children: [
      {
        path: '',
        element: <Settings />,
      },
    ],
  },
  // {
  //   path: 'authentication',
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: 'providers',
  //       element: <AuthProvider />,
  //     },
  //   ],
  // },
  {
    path: 'documentation',
    element: <DashboardLayout />,
    children: [
      {
        path: '',
        element: <Documentation />,
      },
    ],
  },
  {
    path: 'private',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <ProtectedPage />,
      },
    ],
  },
  {
    path: '*',
    element: <AuthLayout />,
    children: [
      {
        path: '*',
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
