import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, createStyles, Paper } from '@mui/material';
import GithubIcon from 'mdi-react/GithubIcon';
import logo from '../../assets/bitloops_175x40_transparent.png';
import { getBitloops } from '../../redux/store';

const useStyles = makeStyles((theme: Theme) => createStyles({
  LoginRoot: {
    // display: 'block',
    fontFamily:
        '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif',
    fontSize: '13px',
    lineHeight: 'normal',
    // background: '#f6f8f9',
    height: '100%',
    // margin: 0,
    paddingTop: '80px',
  },
  Logo: {
    marginBottom: '48px',
  },
  LoginCardLayout: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    // minHeight: '100vh',
  },
  LoginCard: {
    alignItems: 'center',
    // backgroundColor: '#fff',
    // borderRadius: '8px',
    // boxShadow: '0 5px 20px 0 rgb(21 27 38 / 8%)',
    // boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '440px',
    padding: '64px 48px 48px',
    // width: 'calc(100% - 16px)',
  },
  LoginForm: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  GoogleButton: {
    position: 'relative',
    background: '#fff',
    borderColor: '#cbd4db',
    color: '#273240',
    fill: '#6f7782',
    fontSize: '16px',
    height: '48px',
    lineHeight: '48px',
    padding: '0 16px',
    cursor: 'pointer',
    alignSelf: 'stretch',
    marginBottom: '32px',
    alignItems: 'center',
    border: '1px solid',
    borderRadius: '2px',
    boxSizing: 'border-box',
    display: 'inline-flex',
    flexShrink: 0,
    justifyContent: 'center',
    overflow: 'hidden',
    transitionDuration: '.2s',
    transitionProperty: 'background,border,box-shadow,color,fill',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: '#f2f3f5',
    },
  },
  GoogleLogo: {
    left: '32px',
    position: 'absolute',
    display: 'block',
    overflow: 'hidden',
    flex: '0 0 auto',
    height: '18px',
    width: '18px',
    marginRight: '4px',
  },
  SeparatorRow: {
    alignItems: 'center',
    display: 'flex',
    textAlign: 'center',
  },
  SeparatorRowLine: {
    borderTop: '1px solid #e8ecee',
    flex: '1 1 auto',
    marginTop: '8px',
    minWidth: '1px',
    paddingTop: '8px',
  },
  SeparatorRowLabel: {
    color: '#cbd4db !important',
    fontSize: '16px',
    margin: '0 8px',
  },
  EmailLoginForm: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '32px',
    marginBlockEnd: '1em',
    justifyContent: 'center',
  },
  EmailLoginFormLabel: {
    fontSize: '12px',
    lineHeight: '16px',
    color: '#6f7782',
    fontFamily:
        '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif',
  },
  EmailLoginFormInput: {
    height: '36px',
    paddingBottom: '4px',
    paddingTop: '4px',
    marginBottom: '16px',
    marginTop: '8px',
    fontFamily:
        '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif',
    fontSize: '13px',
    // fontWeight: '400',
    lineHeight: '20px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#cbd4db',
    borderRadius: '2px',
    padding: '0 12px',
    boxSizing: 'border-box',
  },
  ForgotPassword: {
    alignSelf: 'flex-start',
    cursor: 'pointer',
    color: '#6f7782',
    fill: '#6f7782',
    textDecoration: 'underline',
    textDecorationStyle: 'solid',
    backgroundColor: 'transparent',
    fontSize: '11px',
    lineHeight: 'normal',
    marginTop: '-10px',
  },
  LoginButton: {
    background: 'linear-gradient(65deg, #7404e4 0, #7d0eec 100%)',
    color: '#FFF !important',
    display: 'flex',
    position: 'relative',
    transition: 'background .15s ease',
    fontFamily: '"Fira Sans", sans-serif',
    fontSize: '15px',
    letterSpacing: '0px',
    fontWeight: 'bold',
    lineHeight: '16px',
    textDecoration: 'none',
    border: 'none',
    borderRadius: '2px',
    cursor: 'pointer',
    justifyContent: 'center',
    padding: '16px 32px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    boxShadow: '0 8px 16px rgb(116 4 228 / 5%)',
    content: '',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    mixBlendMode: 'multiply',
    alignSelf: 'stretch',
    marginTop: '32px',
    '&:hover': {
      boxShadow: '0 8px 16px rgb(116 4 228 / 25%)',
    },
  },
  Signup: {
    marginTop: '24px',
    textAlign: 'center',
    fontSize: '13px',
    lineHeight: '20px',
    color: '#6f7782',
  },
  SignupLink: {
    cursor: 'pointer',
    color: '#14aaf5',
    fill: '#14aaf5',
    textDecoration: 'none',
    marginLeft: '8px',
  },
}));

const SignIn: React.FC = () => {
  const classes: any = useStyles();
  const [error, setError] = useState(null);

  return (
    <div className={classes.LoginRoot}>
      <div className={classes.LoginCardLayout}>
        <Paper elevation={3} style={{ width: '384px' }}>
          <div className={classes.LoginCard}>
            <img
              className={classes.Logo}
              src={logo}
              alt="Bitloops"
              width={175}
              height={40}
            />
            <div className={classes.LoginForm}>
              {error !== null && (
                <div className="py-4 bg-red-600 w-full text-white text-center mb-3">
                  {error}
                </div>
              )}
              <button
                type="button"
                onClick={() => {
                  const bitloops = getBitloops();
                  return bitloops.auth.authenticateWithGoogle();
                }}
                className={classes.GoogleButton}
              >
                <svg className={classes.GoogleLogo} viewBox="0 0 18 18">
                  <path
                    d="M17.64,9.20454545 C17.64,8.56636364 17.5827273,7.95272727 17.4763636,7.36363636 L9,7.36363636 L9,10.845 L13.8436364,10.845 C13.635,11.97 13.0009091,12.9231818 12.0477273,13.5613636 L12.0477273,15.8195455 L14.9563636,15.8195455 C16.6581818,14.2527273 17.64,11.9454545 17.64,9.20454545 L17.64,9.20454545 Z"
                    fill="#4285F4"
                  />
                  <path
                    d="M9,18 C11.43,18 13.4672727,17.1940909 14.9563636,15.8195455 L12.0477273,13.5613636 C11.2418182,14.1013636 10.2109091,14.4204545 9,14.4204545 C6.65590909,14.4204545 4.67181818,12.8372727 3.96409091,10.71 L0.957272727,10.71 L0.957272727,13.0418182 C2.43818182,15.9831818 5.48181818,18 9,18 L9,18 Z"
                    fill="#34A853"
                  />
                  <path
                    d="M3.96409091,10.71 C3.78409091,10.17 3.68181818,9.59318182 3.68181818,9 C3.68181818,8.40681818 3.78409091,7.83 3.96409091,7.29 L3.96409091,4.95818182 L0.957272727,4.95818182 C0.347727273,6.17318182 0,7.54772727 0,9 C0,10.4522727 0.347727273,11.8268182 0.957272727,13.0418182 L3.96409091,10.71 L3.96409091,10.71 Z"
                    fill="#FBBC05"
                  />
                  <path
                    d="M9,3.57954545 C10.3213636,3.57954545 11.5077273,4.03363636 12.4404545,4.92545455 L15.0218182,2.34409091 C13.4631818,0.891818182 11.4259091,0 9,0 C5.48181818,0 2.43818182,2.01681818 0.957272727,4.95818182 L3.96409091,7.29 C4.67181818,5.16272727 6.65590909,3.57954545 9,3.57954545 L9,3.57954545 Z"
                    fill="#EA4335"
                  />
                </svg>
                Login with Google
              </button>
              <button
                type="button"
                onClick={() => {
                  // console.log(auth.user);
                  const bitloops = getBitloops();
                  return bitloops.auth.authenticateWithGitHub();
                }}
                className={classes.GoogleButton}
              >
                <GithubIcon className={classes.GoogleLogo} />
                Login with GitHub
              </button>
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
};
export default SignIn;
