import React from 'react';
import styled from 'styled-components/macro';

import { Alert as MuiAlert } from '@mui/material';
import { spacing } from '@material-ui/system';

import Default from '../dashboards/Default';

const Alert = styled(MuiAlert)(spacing);

const ProtectedPage = (): JSX.Element => (
  <>
    <Alert mb={4} severity="info">
      This page is only visible by authenticated users.
    </Alert>

    <Default />
  </>
);

export default ProtectedPage;
