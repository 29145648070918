import React from 'react';
// import styled, { css } from 'styled-components/macro';
import ReactPerfectScrollbar from 'react-perfect-scrollbar';
import List from '@mui/material/List';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/system';

import { SidebarItemsType } from '../../types/sidebar';
import SidebarNavSection from './SidebarNavSection';

import '../../vendor/perfect-scrollbar.css';

// const baseScrollbar = css`
//   background-color: ${(props) => props.theme.sidebar.background};
//   border-right: 1px solid rgba(0, 0, 0, 0.12);
// `;

const Scrollbar = styled('div')<
{ theme: any }
>`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const PerfectScrollbar = styled(ReactPerfectScrollbar)<
{ theme: any }
>`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const Items = styled('div')<
{ theme: any }
>`
  padding-top: ${(props) => props.theme.spacing(2.5)};
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
`;

type SidebarNavProps = {
  items: {
    title: string;
    pages: SidebarItemsType[];
  }[];
};

const SidebarNav: React.FC<SidebarNavProps> = ({ items }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  // const ScrollbarComponent = (matches ? PerfectScrollbar : Scrollbar) as React.ElementType;
  const ScrollbarComponent = (matches ? PerfectScrollbar : PerfectScrollbar) as React.ElementType;

  return (
    <ScrollbarComponent>
      <List disablePadding>
        <Items theme={theme}>
          {items && items.map((item) => (
            <SidebarNavSection
              component="div"
              key={item.title}
              pages={item.pages}
              title={item.title}
            />
          ))}
        </Items>
      </List>
    </ScrollbarComponent>
  );
};

export default SidebarNav;
