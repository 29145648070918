import React from 'react';

import THEMES from '../constants';

const initialThemeContextState = {
  theme: THEMES.DEFAULT,
  setTheme: (theme: string): void => {
    // TODO implement this if needed
    throw Error('Unimplemented');
  },
};
const ThemeContext = React.createContext(initialThemeContextState);

type ThemeProviderProps = {
  children: React.ReactNode;
};

const ThemeProvider = ({ children }: ThemeProviderProps): JSX.Element => {
  const initialState = (): any => {
    const storedTheme = localStorage.getItem('theme');

    return storedTheme ? JSON.parse(storedTheme) : THEMES.DEFAULT;
  };

  const [theme, _setTheme] = React.useState<string>(initialState());

  const setTheme = (themeString: string): void => {
    localStorage.setItem('theme', JSON.stringify(themeString));
    _setTheme(themeString);
  };

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeProvider, ThemeContext };
