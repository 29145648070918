import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IEnvironment,
  IService, ServicesStatus, ServiceType,
} from '../../definitions/services';
import { UIService } from '../../pages/Services';
import {
  AppDispatch,
  AsyncThunkConfig, MyKnownError, RootState,
} from '../store';

// const mockEnvironments: any = {
//   production: {
//     name: 'Production',
//     description: 'Production environment',
//     workspaceId: 'db24bb48-d2e3-4433-8fd0-79eef2bf63df',
//   },
//   development: {
//     name: 'Development',
//     description: 'Development environment',
//     workspaceId: 'db24bb48-d2e3-4433-8fd0-79eef2bf63df',
//   },
// };

const transformUIServiceData = (data: UIService): IService => {
  const {
    serviceId,
    serviceDescription, serviceInterface,
    serviceName,
    fileData,
    serviceEnvironment,
    environmentSSL,
    environmentTarget,
  } = data;

  const newService: IService = {
    id: serviceId,
    name: serviceName,
    description: serviceDescription,
    type: serviceInterface,
    // tags?: string[];
  };

  if (serviceInterface === ServiceType.REST) {
    newService.meta = {
      swagger: fileData,
    };
  } else {
    newService.meta = {
      proto: fileData,
    };
  }

  if (serviceEnvironment) {
    newService.environments = {
      [serviceEnvironment]: { ssl: environmentSSL, target: environmentTarget },
    };
  }
  return newService;
};

export interface IServicesState {
  status: ServicesStatus;
  services: Record<string, IService>;
  error: string | null
}

const initialState: IServicesState = {
  status: ServicesStatus.IDLE,
  services: {},
  error: null,
};

export const fetchServices = createAsyncThunk<Record<string, IService>, undefined, {
  dispatch: AppDispatch, state: RootState
}>(
  'services/fetchAll',
  async (_, thunkArg: any) => {
    const bitloops = await thunkArg?.extra?.bitloops;
    const workspaceId = thunkArg.getState().workspaces.currentWorkspace.id;
    const response = await bitloops.request(
      'dae0d6cb-7568-4356-b7c9-6050ae31c5b7',
      '60cfb497-b97d-4fea-831e-87b660ae03e6',
      {
        workspaceId,
        workflowId: 'a77f36b9-e1f0-4dba-8ae8-a56a75388400',
        nodeId: 'a568125f-ad16-48cf-bbd7-74a6b57f0c57',
        body: { workspaceId },
      },
    );
    console.log('response workspace services', response);
    if (response.data === null || response.data === undefined) return {};
    return response.data;
  },
);

export const addNewService = createAsyncThunk<any, UIService, {
  dispatch: AppDispatch, state: RootState
}>(
  'services/addNew',
  async (payload, thunkArg: any) => {
    const newService = transformUIServiceData(payload);
    const bitloops = await thunkArg?.extra?.bitloops;
    const workspaceId = thunkArg.getState().workspaces.currentWorkspace.id;
    const response = await bitloops.request(
      'dae0d6cb-7568-4356-b7c9-6050ae31c5b7',
      '60cfb497-b97d-4fea-831e-87b660ae03e6',
      {
        workspaceId,
        workflowId: 'e90038f1-2c5f-4d6c-8b98-dd77e6f33b74',
        nodeId: '11b82ed0-2109-48c0-8466-33b5abeabdab',
        body: { workspaceId, data: { ...newService, workspaceId } },
      },
    );
    console.log('response workspace added service', response);
    return response.response;
  },
);

export const removeService = createAsyncThunk<any, string, AsyncThunkConfig>(
  'services/deleteOne',
  async (payload, thunkApi) => {
    const id = payload;
    const bitloops = await thunkApi?.extra?.bitloops;
    const workspaceId = thunkApi.getState().workspaces?.currentWorkspace?.id;
    try {
      const response = await bitloops.request(
        'dae0d6cb-7568-4356-b7c9-6050ae31c5b7',
        '60cfb497-b97d-4fea-831e-87b660ae03e6',
        {
          workspaceId,
          workflowId: '29c28659-a067-4371-8347-6150340e05b3',
          nodeId: 'e7b6899a-24be-420c-8839-121cec65a82d',
          body: { workspaceId, id },
        },
      );
      console.log('response workspace removed service', response);
      return response.response;
    } catch (error) {
      return thunkApi.rejectWithValue(error as MyKnownError);
    }
  },
);

const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    serviceAdded(state, action: PayloadAction<UIService>) {
      const newService = transformUIServiceData(action.payload);
      state.services[newService.id] = newService;
    },
    serviceRemoved(state, action: PayloadAction<string>) {
      const serviceId = action.payload;
      delete state.services[serviceId];
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchServices.pending, (state) => {
      const status = ServicesStatus.LOADING;
      state.status = status;
    });
    builder.addCase(fetchServices.fulfilled, (
      state,
      action: PayloadAction<Record<string, IService>>,
    ) => {
      console.log('fetch Services fullfilled');
      const status = ServicesStatus.OK;
      state.status = status;
      state.services = action.payload;
    });
    builder.addCase(fetchServices.rejected, (state) => {
      // const status = ServicesStatus.FAILED;
      state.status = ServicesStatus.FAILED;
      // return {
      //   ...state,
      //   status,
      //   services: undefined,
      //   environments: undefined,
      // };
    });
    builder.addCase(addNewService.pending, (state) => {
      const status = ServicesStatus.LOADING;
      state.status = status;
    });
    builder.addCase(addNewService.fulfilled, (state, action) => {
      console.log('addService fullfiled', action.payload);
      const status = ServicesStatus.OK;
      state.status = status;
    });
    builder.addCase(addNewService.rejected, (state) => {
      const status = ServicesStatus.FAILED;
      state.status = status;
    });
    builder.addCase(removeService.pending, (state) => {
      state.status = ServicesStatus.LOADING;
    });
    builder.addCase(removeService.fulfilled, (state, action) => {
      console.log('remove service fullfiled', action.payload);
      state.status = ServicesStatus.OK;
    });
    builder.addCase(removeService.rejected, (state, action) => {
      state.status = ServicesStatus.FAILED;
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error as string;
      }
    });
  },
});

export const { serviceAdded, serviceRemoved, reset: resetServices } = servicesSlice.actions;

export const selectServices = (state: RootState): IServicesState => state.services;

export default servicesSlice.reducer;
