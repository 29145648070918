import React from 'react';
import { Handle, Position } from 'react-flow-renderer';
import {
  ArrowForward,
  ArrowBack,
  MailOutline,
  Timer,
} from '@mui/icons-material';
import {
  NodeTypeName,
  INode,
  IRequestStartNode,
  ITaskNode,
  AuthenticationType,
  IDirectStartNode,
  IMessageStartNode,
  ITimerIntermediateNode,
} from '../../../definitions/node';

export interface NodeProps {
  data: any;
}

const MessageStartEventNode = (props: NodeProps): JSX.Element => {
  const { data } = props;
  return (
    <div className={(data.selected) ? ' selectedNode' : ''}>
      <div className={`request-start-node${(data.selected) ? ' selectedNode' : ''}`}>
        <div id={data.id} className="request-start-node-text">
          <MailOutline />
        </div>
        <Handle
          type="source"
          position={Position.Right}
          id={`${data.id}.out`}
          style={{ top: '50%', borderRadius: 0 }}
        />
      </div>
      <div className="node-description-text">{data.description}</div>
    </div>
  );
};

const DirectStartEventNode = (props: NodeProps): JSX.Element => {
  const { data } = props;
  return (
    <div className={(data.selected) ? ' selectedNode' : ''}>
      <div className="request-start-node">
        <div id={data.id} className="request-start-node-text" />
        <Handle
          type="source"
          position={Position.Right}
          id={`${data.id}.out`}
          style={{ top: '50%', borderRadius: 0 }}
        />
      </div>
      <div className="node-description-text">{data.description}</div>
    </div>
  );
};

const RequestStartEventNode = (props: NodeProps): JSX.Element => {
  const { data } = props;
  return (
    <div className={(data.selected) ? ' selectedNode' : ''}>
      <div className="request-start-node">
        <div id={data.id} className="request-start-node-text">
          <ArrowForward />
        </div>
        <Handle
          type="source"
          position={Position.Right}
          id={`${data.id}.out`}
          style={{ top: '50%', borderRadius: 0 }}
        />
      </div>
      <div className="node-description-text">{data.description}</div>
    </div>
  );
};

const RequestEndEventNode = (props: NodeProps): JSX.Element => {
  const { data } = props;
  return (
    <div className={(data.selected) ? ' selectedNode' : ''}>
      <div className="request-end-node">
        <div id={data.id} className="request-end-node-text">
          <ArrowBack />
        </div>
        <Handle
          type="target"
          position={Position.Left}
          id={`${data.id}.in`}
          style={{ top: '50%', borderRadius: 0 }}
        />
      </div>
      <div className="node-description-text">{data.description}</div>
    </div>
  );
};

const TimerIntermediateEventNode = (props: NodeProps): JSX.Element => {
  const { data } = props;
  return (
    <div className={(data.selected) ? ' selectedNode' : ''}>
      <div className="timer-intermediate-node">
        <Handle
          type="target"
          position={Position.Left}
          id={`${data.id}.in`}
          style={{ top: '50%', borderRadius: 0 }}
        />
        <div id={data.id} className="timer-intermediate-node-text">
          <Timer />
        </div>
        <Handle
          type="source"
          position={Position.Right}
          id={`${data.id}.out`}
          style={{ top: '50%', borderRadius: 0 }}
        />
      </div>
    </div>
  );
};

const TaskActivityNode = (props: NodeProps): JSX.Element => {
  const { data } = props;
  return (
    <div className={(data.selected) ? ' selectedNode' : ''}>
      <div className="task-activity-node">
        <Handle
          type="target"
          position={Position.Left}
          id={`${data.id}.in`}
          style={{ borderRadius: 0 }}
        />
        <div id={data.id}>{data.description}</div>
        <Handle
          type="source"
          position={Position.Right}
          id={`${data.id}.out`}
          style={{ top: '50%', borderRadius: 0 }}
        />
      </div>
    </div>
  );
};

const CallActivityNode = (props: NodeProps): JSX.Element => {
  const { data } = props;
  return (
    <div className={(data.selected) ? ' selectedNode' : ''}>
      <div className="call-activity-node call-activity-node-text">
        <Handle
          type="target"
          position={Position.Left}
          id={`${data.id}.in`}
          style={{ borderRadius: 0 }}
        />
        <div id={data.id}>{data.description}</div>
        <Handle
          type="source"
          position={Position.Right}
          id={`${data.id}.out`}
          style={{ top: '50%', borderRadius: 0 }}
        />
      </div>
    </div>
  );
};

const ExclusiveGatewayNode = (props: NodeProps): JSX.Element => {
  const { data } = props;
  return (
    <div className={(data.selected) ? ' selectedNode' : ''}>
      <div className="exclusive-gateway-node">
        <Handle
          type="target"
          position={Position.Left}
          id={`${data.id}.in`}
          style={{ borderRadius: 0 }}
        />
        <div className="exclusive-gateway-node-rectangle" />
        <div id={data.id} className="exclusive-gateway-node-text">
          {data.label}
        </div>
        <Handle
          type="source"
          position={Position.Top}
          id={`${data.id}.out1`}
          style={{ left: '50%', borderRadius: 0 }}
        />
        <Handle
          type="source"
          position={Position.Right}
          id={`${data.id}.out2`}
          style={{ top: '50%', borderRadius: 0 }}
        />
        <Handle
          type="source"
          position={Position.Bottom}
          id={`${data.id}.out3`}
          style={{ left: '50%', borderRadius: 0 }}
        />
      </div>
    </div>
  );
};

export const getTaskNodeDefaultValues = (nodeId: string): any/* ITaskNode */ => {
  const taskNode = {
    id: nodeId,
    name: '',
    type: {
      name: NodeTypeName.TaskNode,
      parameters: {
        interface: {
          type: 'REST',
        },
        service: 'serviceId',
        serviceVersion: '0.0.1',
      },
    },
    executed: false,
    visual: { x: 10, y: 10 },
  };
  return taskNode;
};

export const getCallActivityNodeDefaultValues = (nodeId: string): any/* ICallActivityNode */ => {
  const callActivityNode = {
    id: nodeId,
    name: '',
    type: {
      name: NodeTypeName.CallNode,
    },
    executed: false,
    visual: { x: 50, y: 50 },
  };
  return callActivityNode;
};

export const getTimerIntermediateNodeDefaultValues = (nodeId: string): ITimerIntermediateNode => {
  const timerIntermediateNode: ITimerIntermediateNode = {
    id: nodeId,
    name: '',
    type: {
      name: NodeTypeName.TimerIntermediateNode,
      parameters: {
        timerDuration: 0,
      },
    },
    visual: { x: 50, y: 50 },
  };
  return timerIntermediateNode;
};

export const getRequestStartNodeDefaultValues =
  (nodeId: string): IRequestStartNode => {
    const requestStartNode = {
      id: nodeId,
      name: 'REST',
      type: {
        name: NodeTypeName.RequestStartNode,
        authentication: {
          type: AuthenticationType.Anonymous,
          Anonymous: true,
        },
      // messageId: undefined,
      // input: [{
      //   name: 'test', type: 'string', required: false, evalValue: true,
      // }],
      },
      visual: { x: 10, y: 10 },
    };
    return requestStartNode;
  };

export const getMessageStartNodeDefaultValues =
  (nodeId: string): IMessageStartNode => {
    const node: IMessageStartNode = {
      id: nodeId,
      name: 'Message',
      type: {
        name: NodeTypeName.MessageStartNode,
        authentication: {
          type: AuthenticationType.Anonymous,
          Anonymous: true,
        },
        messageId: 'message-id',
      // messageId: undefined,
      // input: [{
      //   name: 'test', type: 'string', required: false, evalValue: true,
      // }],
      },
      visual: { x: 10, y: 10 },
    };
    return node;
  };

export const getDirectStartNodeDefaultValues =
  (nodeId: string): IDirectStartNode => {
    const node : IDirectStartNode = {
      id: nodeId,
      name: 'direct',
      type: {
        name: NodeTypeName.DirectStartNode,
        workflowId: 'workflow-id', // TODO remove these?
        workspaceId: 'workspace-id', //
        workflowVersion: 'v1', //
      },
      visual: { x: 10, y: 10 },
    };
    return node;
  };

export const getEndNodeDefaultValues = (nodeId: string): any => {
  const endNode = {
    id: nodeId,
    name: 'end',
    type: {
      name: NodeTypeName.EndNode,
      output: [{
        name: '',
        type: 'string',
        evalValue: '',
      }],
    },
    visual: { x: 10, y: 10 },
  };
  return endNode;
};

export const getExclusiveNodeDefaultValues = (nodeId: string): any => {
  const exclusiveNode = {
    id: nodeId,
    name: 'excl node',
    type: {
      name: NodeTypeName.ExclusiveNode,
      parameters: {
        expression: 'a < b',
        cases: [{
          evalValue: '',
          valueType: '',
          index: 0,
        }],
        default: {},
      },
    },
    visual: { x: 10, y: 10 },
  };
  return exclusiveNode;
};

// export const getNodeDefaultValues = (nodeId: string, type: NodeTypeName): any/* INode */ => {
//   switch (type) {
//     case NodeTypeName.RequestStartNode:
//     case NodeTypeName.DirectStartNode:
//     case NodeTypeName.MessageStartNode:
//       return getRequestStartNodeDefaultValues(nodeId);
//     case NodeTypeName.TaskNode:
//       return getTaskNodeDefaultValues(nodeId);
//     case NodeTypeName.ExclusiveNode:
//       return getExclusiveNodeDefaultValues(nodeId);
//     case NodeTypeName.EndNode:
//       return getEndNodeDefaultValues(nodeId);
//     default:
//       return getTaskNodeDefaultValues(nodeId);
//   }
// };

export const TextNode = (props: NodeProps): JSX.Element => {
  const { data } = props;
  return (
    <div style={{ background: 'transparent', padding: '14px' }}>
      <div id={data.id}>{data.label}</div>
    </div>
  );
};

// TODO create and export other types
export const nodeTypes = {
  [NodeTypeName.DirectStartNode]: DirectStartEventNode,
  [NodeTypeName.MessageStartNode]: MessageStartEventNode,
  [NodeTypeName.RequestStartNode]: RequestStartEventNode,
  [NodeTypeName.EndNode]: RequestEndEventNode,
  [NodeTypeName.TaskNode]: TaskActivityNode,
  [NodeTypeName.CallNode]: CallActivityNode,
  [NodeTypeName.ExclusiveNode]: ExclusiveGatewayNode,
  [NodeTypeName.TimerIntermediateNode]: TimerIntermediateEventNode,
};

// {
//   id: 'edges-e5-6',
//   source: 'edges-5',
//   target: 'edges-6',
//   label: 'styled label',
//   labelStyle: { fill: 'red', fontWeight: 700 },
//   arrowHeadType: 'arrow',
// },
