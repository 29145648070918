export enum ServiceType {
  gRPC = 'gRPC',
  REST = 'REST',
  NATS = 'NATS',
  MESSAGE = 'MESSAGE',
  DYNAMIC_REST = 'DYNAMIC_REST',
}

export interface IEnvironment {
  name: string;
  description: string;
  workspaceId: string;
}

export enum ServicesStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  OK = 'OK',
  FAILED = 'failed',
}

export interface IgRPCServiceEnvironment {
  target: string;
  headers?: Map<string, string>;
  ssl: boolean;
}

export interface IRESTServiceEnvironment {
  baseURI: string;
  headers?: Map<string, string>;
  ssl: boolean;
}

export interface INATSServiceEnvironment {
  topic: string;
  headers?: Map<string, string>;
}

export interface IServiceMeta {
  proto?: string;
  swagger?: string;
}

export interface IService {
  id: string;
  name: string;
  description: string;
  type: ServiceType;
  tags?: string[];
  meta?: IServiceMeta;
  environments?:
    Record<string,
      IgRPCServiceEnvironment |
      IRESTServiceEnvironment |
      INATSServiceEnvironment
    >
}
