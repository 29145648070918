import React, { useEffect, useState, ChangeEvent } from 'react';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';
import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Checkbox,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  CardContent,
  TextField as MuiTextField,
  Card as MuiCard,
  FormControl as MuiFormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
// import { green, orange } from '@mui/material/colors';
import {
  Add as AddIcon,
  DeleteOutline as DeleteOutlineIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  CloudUpload as MuiCloudUpload,
  CloudDone as MuiCloudDone,
} from '@mui/icons-material';
import { spacing, SpacingProps } from '@material-ui/system';

import useAppDispatch from '../../hooks/useAppDispatch';
import { selectAuth } from '../../redux/slices/auth';
import { removeService, selectServices, serviceRemoved } from '../../redux/slices/services';
import {
  removeSecret, secretRemoved, SecretWithoutValues, selectSecrets,
} from '../../redux/slices/secrets';
import CopyToClipboard from './components/CopyToClipboard';

const SECRETS_STRING = 'Secrets';
const ADD_NEW_SERVICE_STRING = 'Add new service';
const SERVICE_INFO_STRING = 'Service Info';

enum Labels {
  ServiceId = 'Service Id',
  SecretName = 'Name',
  ServiceDescription = 'Description',
  Interface = 'Interface',
  Environment = 'Environment',
  Target = 'Target',
  SSL = 'SSL',
  Headers = 'Headers',
}

const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)<{ m?: number }>`
  min-width: 148px;
`;

interface ButtonProps extends SpacingProps {
  component?: string;
}
const Button = styled(MuiButton)<ButtonProps>(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

type RowType = {
  [key: string]: string;
  serviceId: string;
  serviceName: string;
  serviceDescription: string;
  interface: string;
};

function descendingComparator(a: RowType, b: RowType, orderBy: string): number {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: 'desc' | 'asc', orderBy: string) : any {
  return order === 'desc' ?
    (a: RowType, b: RowType) => descendingComparator(a, b, orderBy)
    : (a: RowType, b: RowType) => -descendingComparator(a, b, orderBy);
}

const stableSort = (
  array: Array<SecretWithoutValues>,
  comparator: (a: SecretWithoutValues, b: SecretWithoutValues) => number,
): SecretWithoutValues[] => {
  const stabilizedThis = array.map((el: SecretWithoutValues, index: number) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
};

type HeadCell = {
  id: string;
  alignment: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
  label: string;
  disablePadding?: boolean;
};

const headCells: Array<HeadCell> = [
  { id: 'serviceName', alignment: 'left', label: Labels.SecretName },
  { id: 'actions', alignment: 'right', label: 'Actions' },
];

type ServicesTableHeadProps = {
  numSelected: number;
  order: 'desc' | 'asc';
  orderBy: string;
  rowCount: number;
  onSelectAllClick: (e: any) => void;
  onRequestSort: (e: any, property: string) => void;
};
const SecretsTableHead: React.FC<ServicesTableHeadProps> = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all' }}
          />
        </TableCell>
        {headCells.map((headCell: HeadCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

type ServicesTableToolbarProps = { numSelected: number };
const ServicesTableToolbar = (props: ServicesTableToolbarProps): any => {
  const { numSelected } = props;

  return (
    <Toolbar>
      <ToolbarTitle>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            {SECRETS_STRING}
          </Typography>
        )}
      </ToolbarTitle>
      <Spacer />
      <div>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete" size="large">
              <DeleteOutlineIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list" size="large">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

const SecretsTable = (props: any) : any => {
  const [order, setOrder] = React.useState<'desc' | 'asc'>('asc');
  const [orderBy, setOrderBy] = React.useState('serviceId');
  const [selected, setSelected] = React.useState<Array<string>>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { secrets } = useSelector(selectSecrets);
  // console.log('servicesTable', services);
  const dispatch = useAppDispatch();

  const [rows, setRows] = React.useState<SecretWithoutValues[]>(secrets);
  // Object.keys(services).map((key, index) => ({
  //   serviceId: services[key].id,
  //   serviceName: services[key].name,
  //   serviceDescription: services[key].description,
  //   interface: services[key].type,
  // })),
  //   );

  useEffect(() => {
    setRows(secrets);
  }, [secrets]);

  const handleRequestSort = (event: any, property: string): void => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      const newSelecteds: Array<string> = rows.map((n: SecretWithoutValues) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  // console.log('selected', selected);
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string,
  ): void => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: Array<string> = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = (row: SecretWithoutValues): void => {
    dispatch(secretRemoved(row.id));
    dispatch(removeSecret(row.name));
  };

  const isSelected = (id: string): any => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div>
      <Paper>
        <ServicesTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <SecretsTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index: number) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      // eslint-disable-next-line react/no-array-index-key
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          onClick={(event) => handleClick(event, row.id)}
                        />
                      </TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="right">
                        <CopyToClipboard
                          title={`Secret ID: ${row.id}`}
                          value={row.id ?? ''}
                          doneText=" Copied Secret ID"
                        />

                        <IconButton
                          aria-label="details"
                          component={NavLink}
                          to={`/secrets/${row.id}`}
                          size="large"
                        >
                          <RemoveRedEyeIcon />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          size="large"
                          onClick={() => handleDelete(row)}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default SecretsTable;
