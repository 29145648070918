import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import styled from "styled-components/macro";
// import * as Yup from "yup";
// import { Formik } from "formik";

// import {
//   Alert as MuiAlert,
//   TextField as MuiTextField,
// } from "@mui/material";
// import { spacing } from "@material-ui/system";
import { useSelector } from 'react-redux';
import Signin from '../../components/auth/Signin';
import { selectIsAuthenticated } from '../../redux/slices/auth';

// const Alert = styled(MuiAlert)(spacing);

// const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const Login = (): JSX.Element => {
  const navigate = useNavigate();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  useEffect(() => {
    if (isAuthenticated) navigate('/dashboard');
  });

  // const { signIn } = useAuth();

  return (
    <div><Signin /></div>

  // <Formik
  //   initialValues={{
  //     email: "demo@bootlab.io",
  //     password: "unsafepassword",
  //     submit: false,
  //   }}
  //   validationSchema={Yup.object().shape({
  //     email: Yup.string()
  //       .email("Must be a valid email")
  //       .max(255)
  //       .required("Email is required"),
  //     password: Yup.string().max(255).required("Password is required"),
  //   })}
  //   onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
  //     try {
  //       await signIn(values.email, values.password);

  //       navigate("/private");
  //     } catch (error: any) {
  //       const message = error.message || "Something went wrong";

  //       setStatus({ success: false });
  //       setErrors({ submit: message });
  //       setSubmitting(false);
  //     }
  //   }}
  // >
  //   {({
  //     errors,
  //     handleBlur,
  //     handleChange,
  //     handleSubmit,
  //     isSubmitting,
  //     touched,
  //     values,
  //   }) => (
  //     <form noValidate onSubmit={handleSubmit}>
  //       <Alert mt={3} mb={3} severity="info">
  //         Use <strong>demo@bootlab.io</strong> and{" "}
  //         <strong>unsafepassword</strong> to sign in
  //       </Alert>
  //       {errors.submit && (
  //         <Alert mt={2} mb={3} severity="warning">
  //           {errors.submit}
  //         </Alert>
  //       )}
  //       <TextField
  //         type="email"
  //         name="email"
  //         label="Email Address"
  //         value={values.email}
  //         error={Boolean(touched.email && errors.email)}
  //         fullWidth
  //         helperText={touched.email && errors.email}
  //         onBlur={handleBlur}
  //         onChange={handleChange}
  //         my={2}
  //       />
  //       <TextField
  //         type="password"
  //         name="password"
  //         label="Password"
  //         value={values.password}
  //         error={Boolean(touched.password && errors.password)}
  //         fullWidth
  //         helperText={touched.password && errors.password}
  //         onBlur={handleBlur}
  //         onChange={handleChange}
  //         my={2}
  //       />
  //       <FormControlLabel
  //         control={<Checkbox value="remember" color="primary" />}
  //         label="Remember me"
  //       />
  //       <Button
  //         type="submit"
  //         fullWidth
  //         variant="contained"
  //         color="primary"
  //         disabled={isSubmitting}
  //       >
  //         Sign in
  //       </Button>
  //       <Button
  //         component={Link}
  //         to="/auth/reset-password"
  //         fullWidth
  //         color="primary"
  //       >
  //         Forgot password
  //       </Button>
  //     </form>
  //   )}
  // </Formik>
  );
};

export default Login;
