import React, { useState } from 'react';
// import styled from 'styled-components/macro';
import { Outlet } from 'react-router-dom';
import { CssBaseline, Paper as MuiPaper } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
// import { styled, spacing } from '@material-ui/system';
import { useSelector } from 'react-redux';
import { styled, spacing, Theme } from '@mui/system';
import NavbarWelcome from '../components/navbar/NavbarWelcome';
import Footer from '../components/Footer';
import { selectIsAuthenticated } from '../redux/slices/auth';
import useTheme from '../hooks/useTheme';
import createTheme from '../theme';

const Root = styled('div')`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)<
{ theme: Theme }
>`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Welcome: React.FC = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = (): void => {
    setMobileOpen(!mobileOpen);
  };

  const theme = createTheme(useTheme().theme);
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const isAuthenticated = useSelector(selectIsAuthenticated);

  return (
    <Root>
      <CssBaseline />
      <AppContent>
        {isAuthenticated &&
          (<NavbarWelcome theme={theme} onStopCreation={handleDrawerToggle} />)}
        <MainContent theme={theme} p={isLgUp ? 5 : 5}>
          {children}
          <Outlet />
        </MainContent>
        <Footer />
      </AppContent>
    </Root>
  );
};

export default Welcome;
