import * as React from 'react';
import styled from 'styled-components/macro';
// import { Power } from 'react-feather';
import { useNavigate } from 'react-router-dom';

import {
  IconButton as MuiIconButton,
  Avatar,
  Tooltip,
  Menu,
  MenuItem,
} from '@mui/material';

// import useAuth from '../../hooks/useAuth';
import { useSelector } from 'react-redux';
import { selectUserData } from '../../redux/slices/auth';
import { getBitloops } from '../../redux/store';

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const NavbarUserDropdown = (): JSX.Element => {
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);
  const user = useSelector(selectUserData);
  const navigate = useNavigate();
  // const { signOut } = useAuth();

  const toggleMenu = (event: React.SyntheticEvent): void => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = (): void => {
    setAnchorMenu(null);
  };

  const handleSignOut = async (): Promise<void> => {
    navigate('/login');
    // await auth.signOut();
    const bitloops = getBitloops();
    bitloops.auth.clearAuthentication();
  };

  return (
    <>
      <Tooltip title="Account">
        <IconButton
          aria-owns={anchorMenu ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          {
            user &&
            user.displayName &&
            user.photoURL &&
            <Avatar alt={user.displayName} src={user.photoURL} />
          }
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        {/* <MenuItem onClick={closeMenu}>Profile</MenuItem> */}
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </Menu>
    </>
  );
};

export default NavbarUserDropdown;
