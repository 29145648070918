import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { v4 as uuid } from 'uuid';
import { useNavigate } from 'react-router-dom';

import useAppDispatch from '../../hooks/useAppDispatch';
import { createWorkflow } from '../../redux/slices/workflows';

const NewWorkflowDialog = (): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleCreate = (): void => {
    const workflowId = uuid();
    dispatch(createWorkflow({ workflowId, name, description }));
    setOpen(false);
    navigate(`/workflows/${workflowId}`);
  };
  const handleName = (e: any): void => {
    setName(e.target.value);
  };
  const handleDescription = (e: any): void => {
    setDescription(e.target.value);
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
      >
        New Workflow
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">New Workflow</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To start creating a new workflow just give it a name and a description
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            onChange={handleName}
            required
            fullWidth
          />
          <TextField
            required
            margin="dense"
            id="description"
            label="Description"
            onChange={handleDescription}
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button disabled={(name === '' || description === '')} onClick={handleCreate} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewWorkflowDialog;
