import React from 'react';
import styled from 'styled-components/macro';

import { Button as MuiButton, Menu, MenuItem } from '@mui/material';
import {
  ArrowDropDown,
  Loop as LoopIcon,
} from '@mui/icons-material';
import { spacing } from '@material-ui/system';

const Button = styled(MuiButton)(spacing);

const SmallButton = styled(Button)`
  padding: 4px;
  min-width: 0;

  svg {
    width: 0.9em;
    height: 0.9em;
  }
`;

const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December',
];

const Actions = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <SmallButton size="small" mr={2}>
        <LoopIcon />
      </SmallButton>
      {/* <SmallButton size='small' mr={2}>
        <FilterListIcon />
      </SmallButton> */}
      <Button
        variant="contained"
        color="secondary"
        aria-owns={anchorEl ? 'simple-menu' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        Today:
        {' '}
        {monthNames[new Date(Date.now()).getMonth()]}
        {' '}
        {new Date(Date.now()).getDate()}
        {' '}
        <ArrowDropDown />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>Today</MenuItem>
        <MenuItem onClick={handleClose}>Last 24 hours</MenuItem>
        <MenuItem onClick={handleClose}>This week to date</MenuItem>
        <MenuItem onClick={handleClose}>Last 7 days</MenuItem>
        <MenuItem onClick={handleClose}>Last 30 days</MenuItem>
        <MenuItem onClick={handleClose}>This month to date</MenuItem>
        <MenuItem onClick={handleClose}>Last 30 days</MenuItem>
      </Menu>
    </>
  );
};

export default Actions;
