import React from 'react';
import styled, { withTheme } from 'styled-components/macro';
// import { darken } from 'polished';
// import { Search as SearchIcon } from 'react-feather';
// import { useTranslation } from 'react-i18next';
import { spacing } from '@material-ui/system';
import { useLocation } from 'react-router-dom';

import {
  AppBar as MuiAppBar,
  Button as MuiButton,
  IconButton as MuiIconButton,
  Grid,
  Hidden,
  Menu,
  MenuItem,
  Toolbar,
} from '@mui/material';

import { Menu as MenuIcon, ArrowDropDown } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { Theme } from '@mui/system';
// import NavbarNotificationsDropdown from './NavbarNotificationsDropdown';
// import NavbarMessagesDropdown from './NavbarMessagesDropdown';
// import NavbarLanguagesDropdown from './NavbarLanguagesDropdown';
import NavbarUserDropdown from './NavbarUserDropdown';
import { selectWorkspaces, workspaceChanged, WorkspaceStatuses } from '../../redux/slices/workspaces';
import createTheme from '../../theme';
import useTheme from '../../hooks/useTheme';
import useAppDispatch from '../../hooks/useAppDispatch';
import { resetServices } from '../../redux/slices/services';
import { resetEnvironments } from '../../redux/slices/environments';
import { resetSecrets } from '../../redux/slices/secrets';

const AppBar = styled(MuiAppBar)<
{ theme: Theme }
>`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const Button = styled(MuiButton)(spacing);

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

// const Search = styled.div`
//   border-radius: 2px;
//   background-color: ${(props) => props.theme.header.background};
//   display: none;
//   position: relative;
//   width: 100%;

//   &:hover {
//     background-color: ${(props) => darken(0.05, props.theme.header.background)};
//   }

//   ${(props) => props.theme.breakpoints.up('md')} {
//     display: block;
//   }
// `;

// const SearchIconWrapper = styled.div`
//   width: 50px;
//   height: 100%;
//   position: absolute;
//   pointer-events: none;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   svg {
//     width: 22px;
//     height: 22px;
//   }
// `;

// const Input = styled(InputBase)`
//   color: inherit;
//   width: 100%;

//   > input {
//     color: ${(props) => props.theme.header.search.color};
//     padding-top: ${(props) => props.theme.spacing(2.5)};
//     padding-right: ${(props) => props.theme.spacing(2.5)};
//     padding-bottom: ${(props) => props.theme.spacing(2.5)};
//     padding-left: ${(props) => props.theme.spacing(12)};
//     width: 160px;
//   }
// `;

type NavbarProps = {
  theme: any;
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
};

const Navbar: React.FC<NavbarProps> = ({ theme, onDrawerToggle }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const workspaces = useSelector(selectWorkspaces);

  const location = useLocation();
  const dispatch = useAppDispatch();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleChangeWorkspace = (event: any): void => {
    if (event.target.id !== workspaces.currentWorkspace?.id) {
      console.log('reseting workspace');
      dispatch(workspaceChanged(event.target.id));
      dispatch(resetServices());
      dispatch(resetEnvironments());
      console.log('reseting secrets');
      dispatch(resetSecrets());
    }
    handleClose();
  };

  // const { t } = useTranslation();
  return (
    <>
      <AppBar theme={theme} position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                  size="large"
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                aria-owns={anchorEl ? 'simple-menu' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                key={workspaces.currentWorkspace?.id}
                id={workspaces.currentWorkspace?.id}
              >
                {workspaces.currentWorkspace?.name}
                {' '}
                <ArrowDropDown />
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                { workspaces.status === WorkspaceStatuses.OK &&
                  workspaces.workspaces?.map(
                    (workspace) => (
                      <MenuItem
                        key={workspace.id}
                        id={workspace.id}
                        onClick={handleChangeWorkspace}
                      >{workspace.name}
                      </MenuItem>
                    ),
                  )}
              </Menu>
            </Grid>
            {/* <Grid item>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <Input placeholder={t("Search")} />
              </Search>
            </Grid> */}
            <Grid item xs />
            <Grid item>
              {/* <NavbarMessagesDropdown />
              <NavbarNotificationsDropdown />
              <NavbarLanguagesDropdown /> */}
              <NavbarUserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default withTheme(Navbar);
