/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  configureStore, ThunkAction, Action, Dispatch,
} from '@reduxjs/toolkit';
import { enableMapSet } from 'immer';
import Bitloops, { AuthTypes, BitloopsConfig } from 'bitloops';
import authReducer from './slices/auth';
import applicationsReducer from './slices/applications';
import workspacesReducer from './slices/workspaces';
import workflowsReducer from './slices/workflows';
import servicesReducer from './slices/services';
import draftWorkflowReducer from './slices/draftWorkflow';
import environmentsReducer from './slices/environments';
import secretsReducer from './slices/secrets';

enableMapSet();

export const getBitloops = (): Bitloops => {
  const bitloopsConfig: BitloopsConfig = {
    apiKey: 'ewrewrew',
    workspaceId: 'db24bb48-d2e3-4433-8fd0-79eef2bf63df',
    environmentId: process.env.REACT_APP_ENVIRONMENT_ID!,
    messagingSenderId: 'e3rwewer',
    server: process.env.REACT_APP_BITLOOPS_SERVER!,
    ssl: process.env.REACT_APP_BITLOOPS_SSL?.toLocaleLowerCase() === 'true',
    auth: {
      authenticationType: AuthTypes.User,
      providerId: process.env.REACT_APP_PROVIDER_ID ?? 'bitloops',
      clientId: process.env.REACT_APP_CLIENT_ID ?? 'test_client',
    },
  };
  // console.log('bitloopsConfig', bitloopsConfig);
  return Bitloops.initialize(bitloopsConfig);
};

// .concat(getBitloops())
export const store = configureStore({
  reducer: {
    auth: authReducer,
    applications: applicationsReducer,
    workspaces: workspacesReducer,
    // TODO add projects reducer
    // projects: projectsReducer,
    workflows: workflowsReducer,
    draftWorkflow: draftWorkflowReducer,
    services: servicesReducer,
    environments: environmentsReducer,
    secrets: secretsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    thunk: {
      extraArgument: { bitloops: getBitloops() },
    },
    serializableCheck: false,
  }), // .concat(logger),
});

store.subscribe(() => {
  const { auth } = store.getState();
  if (auth.userData) {
    localStorage.setItem('auth.userData', JSON.stringify(auth.userData));
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type MyKnownError = {
  errorMessage: string;
};

export type AsyncThunkConfig = {
  /** return type for `thunkApi.getState` */
  state: RootState
  /** type for `thunkApi.dispatch` */
  dispatch: AppDispatch
  /** type of the `extra` argument for the thunk middleware,
   *  which will be passed in as `thunkApi.extra` */
  extra: { bitloops: Bitloops }
  /** type to be passed into `rejectWithValue`'s first argument
   *  that will end up on `rejectedAction.payload` */
  rejectValue: MyKnownError
  /** return type of the `serializeError` option callback */
  serializedErrorType?: unknown
  /** type to be returned from the `getPendingMeta` option callback
   *  & merged into `pendingAction.meta` */
  pendingMeta?: unknown
  /** type to be passed into the second argument of `fulfillWithValue`
   * to finally be merged into `fulfilledAction.meta` */
  fulfilledMeta?: unknown
  /** type to be passed into the second argument of `rejectWithValue`
   *  to finally be merged into `rejectedAction.meta` */
  rejectedMeta?: unknown
}
