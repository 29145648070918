import React, {
  useEffect, useState, ChangeEvent, MouseEventHandler,
} from 'react';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';
import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Checkbox,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  CardContent,
  TextField as MuiTextField,
  Card as MuiCard,
  FormControl as MuiFormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  InputAdornment,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
// import { green, orange } from '@mui/material/colors';
import {
  Add as AddIcon,
  DeleteOutline as DeleteOutlineIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import { spacing, SpacingProps } from '@material-ui/system';
import { fetchEnvironments, selectEnvironments } from '../../redux/slices/environments';
import { ServiceType } from '../../definitions/services';
import useAppDispatch from '../../hooks/useAppDispatch';
import NewSecretForm from './NewSecretForm';
import { fetchSecrets } from '../../redux/slices/secrets';
import SecretsTable from './SecretsTable';
import { selectWorkspaces, WorkspaceStatuses } from '../../redux/slices/workspaces';
import { selectAuth } from '../../redux/slices/auth';

const SECRETS_STRING = 'Secrets';

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const Secrets = (): JSX.Element => {
  const workspaces = useSelector(selectWorkspaces);
  const auth = useSelector(selectAuth);
  const dispatch = useAppDispatch();
  // useEffect(() => {
  //   dispatch(fetchSecrets());
  // }, []);

  useEffect(() => {
    if (auth.isAuthenticated &&
      workspaces.status !== WorkspaceStatuses.LOADING) {
      dispatch(fetchSecrets());
      dispatch(fetchEnvironments());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaces.currentWorkspace, workspaces.status, auth]);

  console.log('//');
  return (
    <>
      <Helmet title={SECRETS_STRING} />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {SECRETS_STRING}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>{SECRETS_STRING}</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />
      <NewSecretForm />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <SecretsTable />
        </Grid>
      </Grid>
    </>
  );
};

export default Secrets;
