import React, { useEffect, useState, ChangeEvent } from 'react';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';
import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Checkbox,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  CardContent,
  TextField as MuiTextField,
  Card as MuiCard,
  FormControl as MuiFormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
// import { green, orange } from '@mui/material/colors';
import {
  Add as AddIcon,
  DeleteOutline as DeleteOutlineIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  CloudUpload as MuiCloudUpload,
  CloudDone as MuiCloudDone,
} from '@mui/icons-material';
import { spacing, SpacingProps } from '@material-ui/system';

import {
  addNewService,
  fetchServices,

  removeService, selectServices, serviceAdded, serviceRemoved,
} from '../redux/slices/services';
import { selectWorkspaces, WorkspaceStatuses } from '../redux/slices/workspaces';

import useAppDispatch from '../hooks/useAppDispatch';
import {
  IgRPCServiceEnvironment,
  INATSServiceEnvironment,
  IRESTServiceEnvironment,
  ServicesStatus,
  ServiceType,
} from '../definitions/services';
import { fetchEnvironments, selectEnvironments } from '../redux/slices/environments';
import { selectAuth } from '../redux/slices/auth';

const SERVICES_STRING = 'Services';
const ADD_NEW_SERVICE_STRING = 'Add new service';
const SERVICE_INFO_STRING = 'Service Info';

enum Labels {
  ServiceId = 'Service Id',
  ServiceName = 'Name',
  ServiceDescription = 'Description',
  Interface = 'Interface',
  Environment = 'Environment',
  Target = 'Target',
  SSL = 'SSL',
  Headers = 'Headers',
}

export type UIService = {
  serviceId: string,
  serviceName: string,
  serviceDescription: string,
  serviceInterface: ServiceType,
  fileData?: string
  serviceEnvironment:string,
  environmentSSL: boolean,
  environmentTarget: string,
  // environments:
  //   Record<string, IgRPCServiceEnvironment | IRESTServiceEnvironment | INATSServiceEnvironment>
}

const CloudUpload = styled(MuiCloudUpload)(spacing);
const CloudDone = styled(MuiCloudDone)(spacing);

const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)<{ m?: number }>`
  min-width: 148px;
`;

interface ButtonProps extends SpacingProps {
  component?: string;
}
const Button = styled(MuiButton)<ButtonProps>(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

type RowType = {
  [key: string]: string;
  serviceId: string;
  serviceName: string;
  serviceDescription: string;
  interface: string;
};

function descendingComparator(a: RowType, b: RowType, orderBy: string): number {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: 'desc' | 'asc', orderBy: string) : any {
  return order === 'desc' ?
    (a: RowType, b: RowType) => descendingComparator(a, b, orderBy)
    : (a: RowType, b: RowType) => -descendingComparator(a, b, orderBy);
}

const stableSort = (
  array: Array<RowType>,
  comparator: (a: RowType, b: RowType) => number,
) : any => {
  const stabilizedThis = array.map((el: RowType, index: number) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
};

type HeadCell = {
  id: string;
  alignment: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
  label: string;
  disablePadding?: boolean;
};

const headCells: Array<HeadCell> = [
  { id: 'serviceName', alignment: 'left', label: Labels.ServiceName },
  { id: 'serviceDescription', alignment: 'left', label: Labels.ServiceDescription },
  { id: 'interface', alignment: 'left', label: Labels.Interface },
  { id: 'serviceId', alignment: 'left', label: Labels.ServiceId },
  { id: 'actions', alignment: 'right', label: 'Actions' },
];

type ServicesTableHeadProps = {
  numSelected: number;
  order: 'desc' | 'asc';
  orderBy: string;
  rowCount: number;
  onSelectAllClick: (e: any) => void;
  onRequestSort: (e: any, property: string) => void;
};
const ServicesTableHead: React.FC<ServicesTableHeadProps> = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all' }}
          />
        </TableCell>
        {headCells.map((headCell: HeadCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

type ServicesTableToolbarProps = { numSelected: number };
const ServicesTableToolbar = (props: ServicesTableToolbarProps): any => {
  const { numSelected } = props;

  return (
    <Toolbar>
      <ToolbarTitle>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            {SERVICES_STRING}
          </Typography>
        )}
      </ToolbarTitle>
      <Spacer />
      <div>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete" size="large">
              <DeleteOutlineIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list" size="large">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

const ServicesTable = (props: any) : any => {
  const [order, setOrder] = React.useState<'desc' | 'asc'>('asc');
  const [orderBy, setOrderBy] = React.useState('serviceId');
  const [selected, setSelected] = React.useState<Array<string>>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { services } = useSelector(selectServices);
  // console.log('servicesTable', services);
  const dispatch = useAppDispatch();

  const [rows, setRows] = React.useState<RowType[]>(
    Object.keys(services).map((key, index) => ({
      serviceId: services[key].id,
      serviceName: services[key].name,
      serviceDescription: services[key].description,
      interface: services[key].type,
    })),
  );

  useEffect(() => {
    setRows(
      Object.keys(services).map((key, index) => ({
        serviceId: key,
        serviceName: services[key].name,
        serviceDescription: services[key].description,
        interface: services[key].type,
      })),
    );
  }, [services]);

  const handleRequestSort = (event: any, property: string): any => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>): any => {
    if (event.target.checked) {
      const newSelecteds: Array<string> = rows.map((n: RowType) => n.serviceId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  // console.log('selected', selected);
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string,
  ): any => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: Array<string> = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ): any => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): any => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = (row: UIService): void => {
    console.log('click row.serviceId', row.serviceId);
    if (row.serviceId === 'serviceId1' || row.serviceId === 'serviceId2') {
      alert('Guarded services!');
      return;
    }
    dispatch(serviceRemoved(row.serviceId));
    dispatch(removeService(row.serviceId));
  };

  const isSelected = (id: string): any => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div>
      <Paper>
        <ServicesTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <ServicesTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, index: any) => {
                  // const isItemSelected = isSelected(row.id);
                  const isItemSelected = isSelected(row.serviceId);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${row.serviceId}-${index}`}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          onClick={(event) => handleClick(event, row.serviceId)}
                        />
                      </TableCell>
                      <TableCell align="left">{row.serviceName}</TableCell>
                      <TableCell align="left">{row.serviceDescription}</TableCell>
                      <TableCell align="left">{row.interface}</TableCell>
                      <TableCell align="left">{row.serviceId}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          aria-label="details"
                          component={NavLink}
                          to={`/services/${row.serviceId}`}
                          size="large"
                        >
                          <RemoveRedEyeIcon />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          size="large"
                          onClick={() => handleDelete(row)}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

const NewServiceForm = (): JSX.Element => {
  const { environments } = useSelector(selectEnvironments);
  const [serviceInterface, setServiceInterface] = useState<ServiceType>(ServiceType.gRPC);
  const [serviceName, setServiceName] = useState('');
  const [serviceDescription, setServiceDescription] = useState('');
  const [uploadedFileContent, setUploadedFileContent] = useState('');
  const [uploadedFilename, setUploadedFilename] = useState('');
  const [serviceEnvironment, setServiceEnvironment] = useState<string>(Object.keys(environments)[0] ?? '');
  const [environmentSSL, setEnvironmentSSL] = useState<boolean>(false);
  const [environmentTarget, setEnvironmentTarget] = useState('');

  console.log('new form environments', environments);
  const dispatch = useAppDispatch();

  const handleSelectChange = (event: SelectChangeEvent<unknown>): void => {
    setServiceInterface(event.target.value as ServiceType);
  };

  const handleServiceNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setServiceName(event.target.value);
  };

  const handleServiceDescriptionChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setServiceDescription(event.target.value);
  };

  const handleEnvSelectChange = (event: SelectChangeEvent<string>): void => {
    setServiceEnvironment(event.target.value);
  };

  const handleEnvironmentSSLChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setEnvironmentSSL(event.target.checked);
  };

  const handleEnvironmentTargetChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setEnvironmentTarget(event.target.value);
  };

  const handleUploadFileClicked = (event: ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files && event.target.files[0];

    const fileReader = new FileReader();
    // console.log('file', file);
    if (file) {
      fileReader.readAsText(file);
      fileReader.onload = () => {
        const fileContent = fileReader.result;
        if (fileContent) {
          // console.log('fileContent', fileContent);
          setUploadedFileContent(fileContent as string);
          setUploadedFilename(file.name);
        }
      };
    }
  };

  const handleAdd = (): void => {
    const newService = {
      serviceId: uuid(),
      serviceInterface,
      serviceName,
      serviceDescription,
      fileData: uploadedFileContent,
      serviceEnvironment,
      environmentSSL,
      environmentTarget,
    };
    console.log('new Service', newService);
    dispatch(serviceAdded(newService));
    dispatch(addNewService(newService));
  };
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {SERVICE_INFO_STRING}
        </Typography>

        <Grid container spacing={4}>
          <Grid item md={4}>
            <TextField
              required
              id="service-name"
              label={Labels.ServiceName}
              variant="outlined"
              defaultValue=""
              onChange={handleServiceNameChange}
              fullWidth
              my={2}
            />
          </Grid>
          <Grid item md={4}>
            <FormControl m={2}>
              <InputLabel required id="interface-select-label">{Labels.Interface}</InputLabel>
              <Select
                labelId="service-interface-label"
                id="service-interface"
                value={serviceInterface}
                label={Labels.Interface}
                onChange={handleSelectChange}
              >
                {Object.values(ServiceType)
                  .map((key: any) => <MenuItem key={key} value={key}>{key}</MenuItem>)}

              </Select>
            </FormControl>
          </Grid>
          {(serviceInterface === ServiceType.gRPC || serviceInterface === ServiceType.REST) && (
          <Grid item md={4}>
            <div>
              <input
                required={serviceInterface === ServiceType.gRPC}
                accept={serviceInterface === ServiceType.gRPC ? '.proto' : '.yaml,.yml,.json'}
                style={{ display: 'none' }}
                id="raised-button-file"
                onChange={handleUploadFileClicked}
                // multiple
                type="file"
              />
              <label htmlFor="raised-button-file">
                {uploadedFilename === '' ? (
                  <Button variant="contained" color="primary" component="span">
                    <CloudUpload mr={2} /> Upload {serviceInterface === ServiceType.gRPC ? 'Proto' : 'Swagger'}
                  </Button>
                ) : (
                  <Button variant="contained" color="primary" component="span">
                    <CloudDone mr={2} /> Replace {uploadedFilename}
                  </Button>
                )}
              </label>
            </div>
          </Grid>
          )}
          <Grid item md={12}>
            <TextField
              id="service-description"
              label={Labels.ServiceDescription}
              variant="outlined"
              defaultValue=""
              onChange={handleServiceDescriptionChange}
              fullWidth
              my={2}
            />
          </Grid>
          {(serviceInterface !== ServiceType.MESSAGE &&
            serviceInterface !== ServiceType.DYNAMIC_REST) && (
            <>
              <Grid container item md={4}>
                <FormControl m={2}>
                  <InputLabel required id="interface-select-label">{Labels.Environment}</InputLabel>
                  <Select
                    labelId="service-environment-label"
                    id="service-environment"
                    value={serviceEnvironment}
                    label={Labels.Environment}
                    onChange={handleEnvSelectChange}
                  >
                    {Object.keys(environments)
                      .map((envId: string) => (
                        <MenuItem key={envId} value={envId}>
                          {environments[envId].name}
                        </MenuItem>
                      ))}

                  </Select>
                </FormControl>
              </Grid>
              <Grid container item md={4} alignItems="center" justifyContent="center">
                <FormControlLabel
                  id="environment-ssl"
                  control={(
                    <Switch
                      checked={environmentSSL}
                      onChange={handleEnvironmentSSLChange}
                    />
                  )}
                  label={Labels.SSL}
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  id="environment-target"
                  label={Labels.Target}
                  variant="outlined"
                  defaultValue=""
                  onChange={handleEnvironmentTargetChange}
                  fullWidth
                  my={2}
                />
              </Grid>
            </>
          )}
        </Grid>
        <div>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            mt={4}
            onClick={handleAdd}
          >
            <AddIcon />
            {ADD_NEW_SERVICE_STRING}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

const ServicesList = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const workspaces = useSelector(selectWorkspaces);
  const services = useSelector(selectServices);
  const environment = useSelector(selectEnvironments);
  const auth = useSelector(selectAuth);

  useEffect(() => {
    // if (services.status === ServicesStatus.IDLE) dispatch(fetchServices());
    // if (environment.status === 'idle') dispatch(fetchEnvironments());
    if (auth.isAuthenticated &&
      workspaces.status !== WorkspaceStatuses.LOADING) {
      dispatch(fetchServices());
      dispatch(fetchEnvironments());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaces.currentWorkspace, workspaces.status]);

  return (
    <>
      <Helmet title={SERVICES_STRING} />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {SERVICES_STRING}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            {/* <Link component={NavLink} to="/">
              Pages
            </Link> */}
            <Typography>{SERVICES_STRING}</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />
      <NewServiceForm />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ServicesTable />
        </Grid>
      </Grid>
    </>
  );
};

export default ServicesList;
