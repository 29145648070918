import React from 'react';
import styled, { withTheme } from 'styled-components/macro';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Grid,
  Toolbar,
} from '@mui/material';

import { Close as CloseIcon } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { Theme } from '@mui/system';
import NavbarUserDropdown from './NavbarUserDropdown';
import { selectWorkspaces, workspaceChanged } from '../../redux/slices/workspaces';
import useAppDispatch from '../../hooks/useAppDispatch';
import { resetServices } from '../../redux/slices/services';
import { resetEnvironments } from '../../redux/slices/environments';
import { resetSecrets } from '../../redux/slices/secrets';

const AppBar = styled(MuiAppBar)<
{ theme: Theme }
>`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

type NavbarNewWorkspaceProps = {
  theme: any;
  onStopCreation: React.MouseEventHandler<HTMLElement>;
};

const NavbarNewWorkspace: React.FC<NavbarNewWorkspaceProps> = ({ theme, onStopCreation }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const workspaces = useSelector(selectWorkspaces);

  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleChangeWorkspace = (event: any): void => {
    if (event.target.id !== workspaces.currentWorkspace?.id) {
      console.log('reseting workspace');
      dispatch(workspaceChanged(event.target.id));
      dispatch(resetServices());
      dispatch(resetEnvironments());
      console.log('reseting secrets');
      dispatch(resetSecrets());
    }
    handleClose();
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleRouteChange = () => {
    const path = '/dashboard';
    navigate(path);
  };

  // const { t } = useTranslation();
  return (
    <>
      <AppBar theme={theme} position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={handleRouteChange}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs />
            <Grid item>
              <NavbarUserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default withTheme(NavbarNewWorkspace);
